import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import React, { useEffect, useState } from "react";
import { apiCall } from "utils";
import { API } from "constant";
import TermsOfUseSection from "./TermsOfUseSection";

export default function TermsOfUse() {
  const [headerData, setHeaderData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchThemeOption();
    fetchPageData();
  }, []);

  useEffect(() => {
    if (headerData && pageData) {
      setLoading(false);
    }
  }, [headerData, pageData]);

  const fetchThemeOption = async () => {
    try {
      const response = await apiCall("GET", API.themeOptions, null, {}, true); // Pass true if auth is required
      setHeaderData(response);
    } catch (err) {
      setError(err.message || "Error fetching data");
    }
  };

  const fetchPageData = async () => {
    try {
      const response = await apiCall("GET", API.careersPage, null, {}, true); // Pass true if auth is required
      setPageData(response?.[0]?.acf);
    } catch (err) {
      setError(err.message || "Error fetching data");
    }
  };

  return (
    <>
      <Helmet>
        <title>Privacy Policy - Protecting Your Data and Personal Terms</title>
        <meta
          name="description"
          content="Read our Privacy Policy to understand how we protect your data. Learn about our GDPR compliance, personal terms of use, and commitment to data security."
        />
      </Helmet>
      <div className="flex w-full flex-col gap-14 bg-white sm:gap-7">
        <div className=" flex justify-center md:px-5 border-gray-300 border-b">
          <div className="flex container-xs  w-full flex-col items-center  sm:gap-[116px]">
            <Header data={headerData} className="gap-5 self-stretch py-8 " />
          </div>
        </div>
        {/* privacy policy section */}
        <TermsOfUseSection />
        <Footer data={headerData?.header?.footer_settings} />
      </div>
    </>
  );
}
