import { Img, Heading, Button, Text } from "./..";
import React from "react";

export default function ProductDetails({
  productImage = "images/img_mask_150x120.png",
  productTitle = "Bohemian Bliss Armchair",
  quantityText = "1",
  ...props
}) {
  const [quantity, setQuantity] = React.useState(1);

  return (
    <div {...props} className={`${props.className} flex flex-col items-center gap-[22px] flex-1`}>
      <div className="flex items-start gap-5 self-stretch rounded">
        <Img src={productImage} alt="Product Image" className="h-[150px] w-[38%] self-center rounded object-contain" />
        <div className="flex-1">
          <div className="flex flex-col items-start gap-3">
            <div className="flex items-start gap-3.5 self-stretch">
              <Text size="textlg" as="p" className="w-[84%] self-center !font-marlidedisplay leading-7">
                {productTitle}
              </Text>
              <Button color="french_grey" size="xs" variant="outline" className="w-[30px] rounded-[14px]">
                <Img src="images/img_delete_1.svg" />
              </Button>
            </div>
            <div className="flex w-[56%] items-center justify-center gap-5 rounded border border-solid border-french_grey p-2.5">
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  setQuantity((quantity) => (quantity < 1 ? 0 : quantity - 1));
                }}
                className="flex-1 cursor-pointer"
              >
                <div className="h-px w-[14px] rounded-[1px] bg-black-900_03" />
              </div>
              <Heading size="headinglg" as="h6" className="tracking-[0.36px] !text-space_cadet">
                {quantity}
              </Heading>
              <Img src="images/img_plus.svg" alt="Increment Button" className="h-[14px] w-[14px] rounded" />
            </div>
          </div>
        </div>
      </div>
      <div className="h-px w-full self-stretch bg-french_grey" />
    </div>
  );
}
