export const hostUrl = "https://foreventksa.com/test_wp/wp-json";

export const KEY = "ck_cb760faca656fea13528be624245a44b66753163";
export const SECRATE = "cs_0f7b375f82331249727ba0490083d3167ecf30d0";

export const Paramas = `?consumer_key=${KEY}&consumer_secret=${SECRATE}`;

export const API = {
  themeOptions: "/wp/v2/custom/theme-options",
  homePage: "/wp/v2/pages?slug=home-page",
  getGallery: "/wp/v2/custom/masonry-galleries",
  testimonials: "/wp/v2/custom/testimonials",
  productList: "/wc/v3/products",
  categoryList: "/wc/v3/products/categories",
  singleProduct: "/wc/v3/products/",
  catalogue: "/wp/v2/pages?slug=catalogue",
  aboutUsPage: "/wp/v2/pages?slug=about-us",
  careersPage: "/wp/v2/pages?slug=careers",
  getInspirationCategory: "/wp/v2/categories?post_type=masonry_gallery",
  getJobs: "/wp/v2/job",
  getMedia: "wp/v2/media/",
};
