import { Button, Img, Text } from "../../components";
import Masonry from "react-masonry-css";
import React, { useRef, useState } from "react";

export default function InspirationSection({ data, galleryData }) {
  const [activeVideo, setActiveVideo] = useState(null); // To track the active video being played

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 2,
  };

  const videoRefs = useRef({}); // Store video element references

  const handleClick = (videoId) => {
    setActiveVideo(videoId); // Set the active video on click
    if (videoRefs.current[videoId]) {
      videoRefs.current[videoId].play(); // Play video
    }
  };

  const handlePlayPause = (videoId) => {
    const videoElement = videoRefs.current[videoId];
    if (videoElement.paused) {
      videoElement.play(); // Play video if paused
    } else {
      videoElement.pause(); // Pause video if playing
    }
  };

  return (
    <>
      {/* inspiration section */}
      <div className="self-stretch">
        <div className="flex flex-col items-center justify-center bg-gray-100 py-[60px] md:py-5 sm:py-16">
          <div className="container-xs flex flex-col items-center gap-[60px] md:px-5 sm:gap-[30px]">
            <div className="flex w-[46%] flex-col items-center gap-1.5 md:w-full">
              <Text
                size="text4xl"
                as="p"
                className="!font-marlidedisplay tracking-[0.60px] sm:text-40"
              >
                {data?.title}
              </Text>
              <Text
                as="p"
                className="self-stretch text-center leading-30 !text-gray-800 sm:text-16 sm:leading-20"
              >
                {data?.section_description}
              </Text>
            </div>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="flex w-[90%] "
              columnClassName="masonry-column"
            >
              {galleryData?.map((item) => (
                <div key={item.id} className="relative p-2 sm:p-2">
                  {item.acf.video ? (
                    <div className="relative">
                      <video
                        ref={(el) => (videoRefs.current[item.id] = el)} // Store the reference for the current video
                        className="w-full h-auto  shadow-md"
                        onClick={() => handlePlayPause(item.id)} // Custom play/pause logic on video click
                        controls={false} // Remove default controls
                      >
                        <source src={item.acf.video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      {activeVideo !== item.id && (
                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg">
                          <Button
                            color="gray_300"
                            size="3xl"
                            variant="outline"
                            className="w-[70px] rounded-[34px]"
                            onClick={() => handleClick(item.id)}
                          >
                            <Img src="images/img_user.svg" />
                          </Button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <img
                      src={item.featured_image}
                      alt={item.title}
                      className="w-full h-auto shadow-md cursor-pointer"
                    />
                  )}
                </div>
              ))}
            </Masonry>
            <a href="https://www.youtube.com/embed/bv8Fxk0sz7I" target="_blank">
              <Button
                color="black_900_03"
                variant="outline"
                shape="round"
                rightIcon={
                  <div className="flex h-[10px] w-[4px] items-center justify-center">
                    <Img
                      src="images/img_arrowright_black_900_03.svg"
                      alt="Arrow Right"
                      className="mb-1 mt-1.5 h-[10px] w-[4px]"
                    />
                  </div>
                }
                className="min-w-[182px] gap-2.5 font-semibold"
              >
                {data?.view_more_button_text}
              </Button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
