import { Link } from "react-router-dom";
import { Text, Img } from "./..";
import React from "react";

export default function UserProfile({ data, ...props }) {
  console.log("name", data);

  return (
    <div className={`${props.className} flex flex-col items-center gap-[18px]`}>
      <Link to={`/productpage/${data?.id}`}>
        <Img
          src={data?.images?.[0]?.src}
          alt="Product Image"
          className="sm:h-[212px] h-[377px] w-full object-cover"
        />
        <Text
          as="p"
          className="!text-gray-900_01 hover:cursor-pointer mt-8 flex justify-center"
        >
          {data?.name}
        </Text>
        {/* </Link> */}
      </Link>
    </div>
  );
}
