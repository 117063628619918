import { Img, Button, CheckBox, TextArea, SelectBox, DatePicker, Input, Text } from "../../components";
import React from "react";

const dropDownOptions = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" },
];

export default function EnquiryFormSection() {
  return (
    <>
      {/* enquiry form section */}
      <div>
        <div className="flex flex-col items-center">
          <Text size="text4xl" as="p" className="relative z-[1] !font-marlidedisplay tracking-[0.60px]">
            Enquire
          </Text>
          <div className="relative mt-[-30px] flex items-center self-stretch md:flex-col">
            <div className="flex flex-1 flex-col items-end gap-6 md:self-stretch md:px-5">
              <div className="flex flex-col items-end gap-3 self-stretch">
                <div className="flex flex-col gap-3 self-stretch">
                  <div className="flex justify-end gap-2.5 md:flex-col">
                    <Input
                      shape="square"
                      type="text"
                      name="First Name Input"
                      placeholder={`First name`}
                      className="w-[32%] border-[0.5px] border-foundation-dark-lighter md:w-full"
                    />
                    <Input
                      shape="square"
                      type="text"
                      name="Last Name Input"
                      placeholder={`Last name`}
                      className="w-[32%] border-[0.5px] border-foundation-dark-lighter md:w-full"
                    />
                  </div>
                  <div className="flex justify-end gap-2.5 md:flex-col">
                    <Input
                      shape="square"
                      type="number"
                      name="Mobile Number Input"
                      placeholder={`Mobile number`}
                      className="w-[32%] border-[0.5px] border-foundation-dark-lighter md:w-full"
                    />
                    <Input
                      shape="square"
                      type="email"
                      name="Email Input"
                      placeholder={`Email address`}
                      className="w-[32%] border-[0.5px] border-foundation-dark-lighter md:w-full"
                    />
                  </div>
                </div>
                <Input
                  shape="square"
                  type="text"
                  name="Company Name Input"
                  placeholder={`Company name`}
                  className="w-[64%] border-[0.5px] border-foundation-dark-lighter"
                />
                <div className="flex justify-end gap-3 self-stretch md:flex-col">
                  <DatePicker
                    name="Start Date Picker"
                    placeholder={`Event start date`}
                    className="flex w-[20%] gap-[34px] border-[0.5px] border-solid border-foundation-dark-lighter bg-white px-5 py-3 text-[16px] text-foundation-dark-light__hover md:w-full"
                  />
                  <DatePicker
                    name="End Date Picker"
                    placeholder={`Event end date`}
                    className="flex w-[20%] gap-[34px] border-[0.5px] border-solid border-foundation-dark-lighter bg-white px-5 py-3 text-[16px] text-foundation-dark-light__hover md:w-full"
                  />
                  <SelectBox
                    shape="square"
                    indicator={<Img src="images/img_down_1.svg" alt="Down 1" className="h-[18px] w-[18px]" />}
                    name="Location Dropdown"
                    placeholder={`Event location`}
                    options={dropDownOptions}
                    className="w-[20%] gap-4 border-[0.5px] border-solid border-foundation-dark-lighter md:w-full"
                  />
                </div>
                <TextArea
                  shape="square"
                  name="Message TextArea"
                  placeholder={`Message`}
                  className="w-[64%] !border-foundation-dark-lighter text-foundation-dark-light__hover"
                />
              </div>
              <div className="flex justify-center self-stretch p-2.5">
                <div className="flex gap-[52px] sm:flex-col">
                  <CheckBox
                    name="PDF Catalog Checkbox"
                    label="Send me a PDF catalogue"
                    id="PDFCatalogCheckbox"
                    className="gap-2.5 text-[14px] font-semibold text-black-900_03"
                  />
                  <CheckBox
                    name="Newsletter Checkbox"
                    label="Subscribe to our newsletter"
                    id="NewsletterCheckbox"
                    className="gap-2.5 text-[14px] font-semibold text-black-900_03"
                  />
                </div>
              </div>
              <Button shape="round" className="min-w-[684px] font-semibold">
                Submit
              </Button>
            </div>
            <Img
              src="images/img_pictograms_beige_02_gray_300_02.png"
              alt="Pictogram Image"
              className="relative ml-[-28px] h-[620px] w-[28%] object-contain md:ml-0 md:w-full"
            />
          </div>
        </div>
      </div>
    </>
  );
}
