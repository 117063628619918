import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ProductPageSection from "./ProductPageSection";
import ProductPairingSection from "./ProductPairingSection";
import React, { useEffect, useState } from "react";
import { apiCall } from "utils";
import { API } from "constant";
import { useParams } from "react-router-dom";

export default function ProductPagePage() {
  const [headerData, setHeaderData] = useState(null);
  const [productData, setProductData] = useState([]);
  const [realtedProductData, setRealtedProductData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetchThemeOption();
    fetchProductData();
  }, []);

  const fetchThemeOption = async () => {
    try {
      const response = await apiCall("GET", API.themeOptions, null, {}, true); // Pass true if auth is required
      setHeaderData(response);
    } catch (err) {
      setError(err.message || "Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  const fetchProductData = async () => {
    try {
      const response = await apiCall(
        "GET",
        API.singleProduct + `${id}`,
        null,
        {},
        true
      ); // Pass true if auth is required
      setProductData(response);

      if (response?.related_ids) {
        await fetchRelatedProductData(response?.related_ids);
      }
    } catch (err) {
      setError(err.message || "Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  const fetchRelatedProductData = async (id) => {
    try {
      const response = await apiCall(
        "GET",
        API.singleProduct + `?include=${id.toString()}`,
        null,
        {},
        true
      ); // Pass true if auth is required
      setRealtedProductData(response);
      console.log("response?.related_ids", response);
    } catch (err) {
      setError(err.message || "Error fetching data");
    } finally {
      setLoading(false);
    }
    console.log("function call");
  };

  return (
    <>
      <Helmet>
        <title>Bohemian Bliss Chair - Product Details - Forevent KSA</title>
        <meta
          name="description"
          content="Experience comfort and style with the Bohemian Bliss Chair from Forevent. Perfect for any event, this armchair pairs well with elegant tables and offers a luxurious seating option."
        />
      </Helmet>
      <div className="w-full bg-white overflow-hidden">
        <div className="flex flex-col items-center gap-9">
          <div className="mt-8 flex flex-col items-center gap-[34px] self-stretch">
            <div className="container-xs md:px-5">
              <Header className="gap-5" data={headerData} />
            </div>

            <ProductPageSection data={productData} />

            <ProductPairingSection data={realtedProductData} />
          </div>
        </div>
        <div>
          <Footer
            className="mt-[18px]"
            data={headerData?.header?.footer_settings}
          />
        </div>
      </div>
    </>
  );
}
