import React from "react";
import PropTypes from "prop-types";

const shapes = {
  circle: "rounded-[50%]",
  round: "rounded",
};
const variants = {
  outline: {
    gray_300: "border-gray-300 border-2 border-solid",
    black_900_03: "border-black-900_03 border border-solid text-black-900_03",
    gray_900_01: "border-gray-900_01 border border-solid text-black-900_03",
    french_grey: "border-french_grey border border-solid",
    gray_800: "border-gray-800 border border-solid text-black-900_03",
  },
  fill: {
    blue_gray_300_87: "bg-blue_gray-300_87",
    white: "bg-white text-blue_gray-900",
    gray_300_01: "bg-gray-300_01",
    black_900_03: "bg-black-900_03 text-white",
    deep_orange_A400: "bg-deep_orange-a400 text-white",
  },
};
const sizes = {
  "3xl": "h-[70px] px-[18px]",
  "2xl": "h-[52px] px-3",
  "4xl": "h-[100px] px-[26px]",
  sm: "h-[32px] px-3 text-[14px]",
  xs: "h-[32px] px-2.5",
  md: "h-[40px] px-3.5 text-[14px]",
  lg: "h-[46px] px-4 text-[16px]",
  xl: "h-[50px] px-[22px] text-[16px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "xl",
  color = "deep_orange_A400",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex flex-row items-center justify-center text-center cursor-pointer whitespace-nowrap ${shape && shapes[shape]} ${size && sizes[size]} ${variant && variants[variant]?.[color]}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["circle", "round"]),
  size: PropTypes.oneOf(["3xl", "2xl", "4xl", "sm", "xs", "md", "lg", "xl"]),
  variant: PropTypes.oneOf(["outline", "fill"]),
  color: PropTypes.oneOf([
    "gray_300",
    "black_900_03",
    "gray_900_01",
    "french_grey",
    "gray_800",
    "blue_gray_300_87",
    "white",
    "gray_300_01",
    "deep_orange_A400",
  ]),
};

export { Button };
