import { Text } from "../../components";
import React from "react";

export default function CatalogueSection() {
  return (
    <>
      {/* catalogue section */}
      <div className="flex h-[168px] items-end justify-center self-stretch bg-[url(/public/images/img_group_12.png)] bg-cover bg-no-repeat py-[38px] md:h-auto sm:py-5">
        <div className="container-xs mt-3 flex justify-center px-14 md:px-5">
          <Text
            size="text4xl"
            as="p"
            className="!font-marlidedisplay tracking-[0.60px] !text-white"
          >
            Catalogue
          </Text>
        </div>
      </div>
    </>
  );
}
