import { Helmet } from "react-helmet";
import { Img, Text, Button } from "../../components";
import Header from "../../components/Header";
import MarketingProfile from "../../components/MarketingProfile";
import JobApplicationSection from "./JobApplicationSection";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { apiCall } from "utils";
import { API } from "constant";
import Footer from "components/Footer";

export default function CareersPage() {
  const [headerData, setHeaderData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [jobsData, setjobsData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const formRef = useRef(null);

  useEffect(() => {
    fetchThemeOption();
    fetchPageData();
    fetchJobsData();
  }, []);

  useEffect(() => {
    if (headerData && pageData) {
      setLoading(false);
    }
  }, [headerData, pageData]);

  const fetchThemeOption = async () => {
    try {
      const response = await apiCall("GET", API.themeOptions, null, {}, true); // Pass true if auth is required
      setHeaderData(response);
    } catch (err) {
      setError(err.message || "Error fetching data");
    }
  };

  const fetchPageData = async () => {
    try {
      const response = await apiCall("GET", API.careersPage, null, {}, true); // Pass true if auth is required
      setPageData(response?.[0]?.acf);
    } catch (err) {
      setError(err.message || "Error fetching data");
    }
  };
  const fetchJobsData = async () => {
    try {
      const response = await apiCall("GET", API.getJobs, null, {}, true); // Pass true if auth is required
      setjobsData(response);
    } catch (err) {
      setError(err.message || "Error fetching data");
    }
  };

  const handleJobSelection = (jobId) => {
    setSelectedJobId(jobId);
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>Join Our Team - Careers at Forevent</title>
        <meta
          name="description"
          content="Explore career opportunities at Forevent. We're hiring passionate individuals for roles like Marketing Executive, Head of Development, and Event Coordinator."
        />
      </Helmet>
      <div className="w-full bg-white">
        <div className=" flex justify-center md:px-5 border-gray-300 border-b">
          <div className="flex container-xs  w-full flex-col items-center  sm:gap-[116px]">
            <Header data={headerData} className="gap-5 self-stretch py-8 " />
          </div>
        </div>
        <div className="mt-8 flex flex-col items-center">
          <div className="container-xs mt-[46px] md:px-5">
            <div className="flex flex-col items-center gap-[82px] md:gap-[61px] sm:gap-[41px]">
              <div className="flex w-[46%] flex-col items-center gap-2.5 md:w-full">
                <Text
                  size="text4xl"
                  as="p"
                  className="!font-marlidedisplay tracking-[0.60px]"
                >
                  {pageData?.title}
                </Text>
                <Text
                  as="p"
                  className="self-stretch text-center leading-20 !text-light_text"
                >
                  {pageData?.description}
                </Text>
              </div>
              <div className="flex flex-col items-start gap-5 self-stretch">
                <Text
                  size="textxl"
                  as="p"
                  className="!font-marlidedisplay tracking-[0.30px]"
                >
                  {pageData?.open_position_text}
                </Text>
                <div className="flex flex-col gap-5 self-stretch">
                  <Suspense fallback={<div>Loading feed...</div>}>
                    {jobsData.map((d, index) => (
                      <MarketingProfile
                        data={d}
                        pageData={pageData}
                        key={"positionsList" + index}
                        onApplyClick={() => handleJobSelection(d.id)}
                      />
                    ))}
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 self-stretch">
            {/* job application section */}
            <JobApplicationSection
              jobsData={jobsData}
              selectedJobId={selectedJobId}
              formRef={formRef}
            />
          </div>
        </div>
        <Footer data={headerData?.header?.footer_settings} />
      </div>
    </>
  );
}
