import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Homepage from "pages/Homepage";
import Catalogue from "pages/Catalogue";
import ProductPage from "pages/ProductPage";
import InspirationPage from "pages/InspirationPage";
import ViewCart from "pages/ViewCart";
import Enquirewithproducts from "pages/Enquirewithproducts";
import Enquirewithoutproducts from "pages/Enquirewithoutproducts";
import AboutUs from "pages/AboutUs";
import TermsorPrivacyPolicy from "pages/PrivacyPolicy";
import Careers from "pages/Careers";
import TermsOfUse from "pages/TermsOfUse";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "*", element: <NotFound /> },
    {
      path: "/",
      element: <Homepage />,
    },
    {
      path: "catalogue",
      element: <Catalogue />,
    },
    {
      path: "productpage/:id",
      element: <ProductPage />,
    },
    {
      path: "inspiration",
      element: <InspirationPage />,
    },
    {
      path: "viewcart",
      element: <ViewCart />,
    },
    {
      path: "enquire",
      element: <Enquirewithproducts />,
    },
    {
      path: "enquirewithoutproducts",
      element: <Enquirewithoutproducts />,
    },
    {
      path: "aboutus",
      element: <AboutUs />,
    },
    {
      path: "privacy-policy",
      element: <TermsorPrivacyPolicy />,
    },
    {
      path: "careers",
      element: <Careers />,
    },
    {
      path: "terms-of-use",
      element: <TermsOfUse />,
    },
  ]);

  return element;
};

export default ProjectRoutes;
