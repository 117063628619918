import { Helmet } from "react-helmet";
import {
  Img,
  Button,
  Text,
  Slider,
  SelectBox,
  Heading,
  Input,
} from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { CloseSVG } from "../../components/Input/close.jsx";
import UserProfile from "../../components/UserProfile";
import CatalogueSection from "./CatalogueSection";
import React, { useEffect, useState } from "react";
import {
  AccordionItemPanel,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemState,
  Accordion,
  AccordionItem,
} from "react-accessible-accordion";
import { apiCall } from "utils";
import { API } from "constant";

const dropDownOptions = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" },
];

export default function CataloguePage() {
  const sliderRef = React.useRef(null);
  const [searchBarValue, setSearchBarValue] = React.useState("");
  const [headerData, setHeaderData] = useState(null);
  const [productList, setProductList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [catalogueData, setCatalogueData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchThemeOption();
    fetchProductList();
    fetchCategoryList();
    fetchCatalogueData();
  }, []);

  const fetchThemeOption = async () => {
    try {
      const response = await apiCall("GET", API.themeOptions, null, {}, true); // Pass true if auth is required
      setHeaderData(response);
    } catch (err) {
      setError(err.message || "Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  const fetchProductList = async () => {
    try {
      const response = await apiCall("GET", API.productList, null, {}, true); // Pass true if auth is required
      setProductList(response);
    } catch (err) {
      setError(err.message || "Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  const fetchCategoryList = async () => {
    try {
      const response = await apiCall(
        "GET",
        API.categoryList + "?orderby=id",
        null,
        {},
        true
      ); // Pass true if auth is required
      setCategoryList(
        response.filter((category) => category.slug !== "uncategorized")
      );
    } catch (err) {
      setError(err.message || "Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  const fetchCatalogueData = async () => {
    try {
      const response = await apiCall("GET", API.catalogue, null, {}, true); // Pass true if auth is required
      setCatalogueData(response?.[0]?.acf);
    } catch (err) {
      setError(err.message || "Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  console.log("catalogueData", catalogueData);

  return (
    <>
      <Helmet>
        <title>Furniture Catalogue - Forevent KSA</title>
        <meta
          name="description"
          content="Explore Forevent's extensive furniture catalogue for your next event. Featuring seating, tables, and exclusive decor. Download our catalogue and start planning now."
        />
      </Helmet>
      <div className="w-full bg-white">
        <div className="flex flex-col items-center gap-9">
          <div className="mt-8 flex flex-col items-center gap-[34px] self-stretch">
            <div className="container-xs md:px-5">
              <Header className="gap-5" data={headerData} />
            </div>

            {/* catalogue section */}
            <CatalogueSection />
          </div>
          <div className="container-xs flex flex-col items-center gap-[68px] md:px-5 sm:gap-[34px]">
            <div className="flex items-start self-stretch md:flex-col">
              <div className="flex w-[22%] flex-col items-start gap-[30px] md:w-full">
                <Input
                  color="french_grey"
                  variant="outline"
                  shape="round"
                  name="Search Bar"
                  placeholder={`Search Product`}
                  value={searchBarValue}
                  onChange={(e) => setSearchBarValue(e.target.value)}
                  suffix={
                    searchBarValue?.length > 0 ? (
                      <CloseSVG onClick={() => setSearchBarValue("")} />
                    ) : null
                  }
                  className="w-[86%] text-gray-700_01 sm:hidden"
                />
                <div className="relative h-[694px] content-end self-stretch md:h-auto sm:hidden">
                  <div className="mx-auto mb-40 flex flex-1 flex-col items-start gap-[78px] md:gap-[58px] sm:gap-[39px]"></div>
                  <Accordion
                    preExpanded={[1]}
                    className="absolute bottom-0 left-0 right-0 top-0 m-auto flex-1 flex-col gap-14"
                  >
                    {categoryList?.map((category, index) => (
                      <AccordionItem
                        uuid={index}
                        key={`Categories List${index}`}
                      >
                        <div className="flex flex-1 flex-col gap-4">
                          <AccordionItemHeading className="w-full">
                            <AccordionItemButton>
                              <AccordionItemState>
                                {(props) => (
                                  <>
                                    <div className="flex items-start justify-between gap-5 py-7">
                                      <div className="flex items-center gap-1.5 self-center">
                                        <Img
                                          src={category?.image?.src}
                                          alt="Seating Icon"
                                          className="h-[24px] w-[24px] object-cover"
                                        />
                                        <Heading
                                          as="h1"
                                          className="!text-gray-900"
                                        >
                                          {category?.name}
                                        </Heading>
                                      </div>
                                      {props?.expanded ? (
                                        <Img
                                          src="images/img_vector_1x14.png"
                                          alt="Seating Expand Icon"
                                          className="mt-2.5 h-px object-cover"
                                        />
                                      ) : (
                                        <Img
                                          src="images/img_plus.svg"
                                          alt="New Expand Icon"
                                          className="h-[14px] w-[14px]"
                                        />
                                      )}
                                    </div>
                                  </>
                                )}
                              </AccordionItemState>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="mb-7 flex flex-col items-start gap-3.5">
                              <Text as="p" className="!text-gray-800">
                                Chairs
                              </Text>
                              <Text as="p" className="!text-gray-800">
                                Sofas and Armchairs
                              </Text>
                              <Text as="p" className="!text-gray-800">
                                Bar Stools
                              </Text>
                              <Text as="p" className="!text-gray-800">
                                Poufs and Ottomans
                              </Text>
                            </div>
                          </AccordionItemPanel>
                        </div>
                        <div className="h-px w-[100%] rotate-[0deg] bg-french_grey" />
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div>
              </div>
              <div className="flex flex-1 flex-col  self-center md:gap-[60px] md:self-stretch sm:gap-10">
                <div className="ml-[38px] flex items-center justify-between gap-5 md:ml-0 mb-20">
                  <Text
                    size="textxs"
                    as="p"
                    className="uppercase !text-gray-800"
                  >
                    Showing 1–12 of 32 results
                  </Text>
                </div>
                <div className="ml-[38px] grid grid-cols-3 sm:grid-cols-2 justify-center gap-6 md:ml-0 md:grid-cols-2 ">
                  {productList?.map((product, index) => {
                    return (
                      <UserProfile data={product} className="w-full mb-7" />
                    );
                  })}
                </div>
              </div>
            </div>

            {catalogueData?.show_download_catalogue && (
              <div className="mx-auto flex w-full self-stretch mt-10">
                <div className="relative h-[196px] content-center md:h-auto">
                  <div
                    className="mx-auto flex-1 bg-contain rounded"
                    style={{
                      backgroundImage: `url("images/img_rectangle_45.png")`,
                    }}
                  >
                    <div className="flex items-center  justify-end gap-[25px] md:flex-col p-7">
                      <div className="flex w-[34%] z-50 flex-col items-start md:w-full">
                        <Text
                          size="text3xl"
                          as="p"
                          className="!font-marlidedisplay mb-4 tracking-[0.45px] !text-gray-300 sm:text-35"
                        >
                          {catalogueData?.title}
                        </Text>
                        <Text
                          as="p"
                          className="w-full !text-gray-300 leading-25 text-16 sm:text-16"
                        >
                          {catalogueData?.description}
                        </Text>
                      </div>
                      <div className="flex z-20 w-[52%] items-center justify-center md:w-full md:flex-col">
                        <div className="relative z-[1] flex flex-1 items-center justify-between gap-5 rounded bg-white md:self-stretch">
                          <Text
                            as="p"
                            className="ml-[18px] !text-foundation-dark-light__hover"
                          >
                            {catalogueData?.email_placeholder}
                          </Text>
                          <Button
                            size="lg"
                            className="min-w-[98px] rounded-br rounded-tr font-semibold"
                          >
                            {catalogueData?.email_button_text}
                          </Button>
                        </div>
                        <Img
                          src="images/img_pictograms_blue_10.png"
                          alt="Email Icon"
                          className="relative opacity-30 ml-[-26px] h-[194px] w-[194px] rounded-sm object-cover md:ml-0 md:w-full sm:absolute sm:h-[147px] sm:w-[147px] sm:right-0 sm:bottom-0"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="flex gap-2 my-10">
              <Button
                color="blue_gray_300_87"
                size="xs"
                shape="round"
                onClick={() => {
                  sliderRef?.current?.slidePrev();
                }}
                className="w-[32px]"
              >
                <Img src="images/img_arrow_left.svg" />
              </Button>
              <Button
                color="white"
                size="sm"
                shape="round"
                className="min-w-[32px] border border-solid border-black-900_03 font-inter font-semibold !text-black-900_03"
              >
                1
              </Button>
              <Button
                color="white"
                size="sm"
                shape="round"
                className="min-w-[32px] border border-solid border-gray-300_03 font-inter font-medium"
              >
                2
              </Button>
              <Button
                color="white"
                size="sm"
                shape="round"
                className="min-w-[32px] border border-solid border-gray-300_03 font-inter font-bold"
              >
                ...
              </Button>
              <Button
                color="white"
                size="sm"
                shape="round"
                className="min-w-[32px] border border-solid border-gray-300_03 font-inter font-medium"
              >
                9
              </Button>
              <Button
                color="white"
                size="sm"
                shape="round"
                className="min-w-[32px] border border-solid border-gray-300_03 font-inter font-medium"
              >
                10
              </Button>
              <Button
                color="white"
                size="xs"
                shape="round"
                onClick={() => {
                  sliderRef?.current?.slideNext();
                }}
                className="w-[32px] border border-solid border-gray-300_03"
              >
                <Img src="images/img_arrow_right_blue_gray_100.svg" />
              </Button>
            </div>
          </div>
        </div>

        <div>
          <Footer
            className="mt-[18px]"
            data={headerData?.header?.footer_settings}
          />
        </div>
      </div>
    </>
  );
}
