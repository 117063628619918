import { API } from "constant";
import { Img, Heading, Button, Text } from "./..";
import React, { useEffect, useState } from "react";
import { apiCall } from "utils";

export default function MarketingProfile({
  data,
  pageData,
  onApplyClick,
  ...props
}) {
  const [imageSrcs, setImageSrcs] = useState([]);

  useEffect(() => {
    const fetchImageSrcs = async () => {
      const imageSrcs = await Promise.all(
        data?.acf?.job_details?.map((item) => fetchThemeOption(item.image))
      );
      setImageSrcs(imageSrcs);
    };
    fetchImageSrcs();
  }, [data]);

  const fetchThemeOption = async (id) => {
    try {
      const response = await apiCall("GET", API.getMedia + id, null, {}, true); // Pass true if auth is required

      return response.source_url;
    } catch (err) {
      console.log(err.message || "Error fetching data");
    }
  };

  console.log("response", data);

  return (
    <div
      {...props}
      className={`${props.className} flex items-center p-[26px] sm:p-5 border-french_grey border border-solid bg-white shadow-xs flex-1 rounded-[12px]`}
    >
      <div className="flex w-full flex-col gap-4">
        <div className="flex items-center justify-center md:flex-col">
          <div className="flex flex-1 flex-col items-start md:self-stretch">
            <Text
              size="textxl"
              as="p"
              className="!font-marlidedisplay !font-medium tracking-[0.30px]"
            >
              {data?.title?.rendered}
            </Text>
            <div
              className="w-[90%] leading-6 !text-gray-800 md:w-full"
              dangerouslySetInnerHTML={{ __html: data?.content?.rendered }}
            />
          </div>
          <Button
            shape="round"
            onClick={onApplyClick}
            rightIcon={
              <div className="flex h-[12px] w-[4px] items-center justify-center">
                <Img
                  src="images/img_arrowright_white.svg"
                  alt="Arrow Right"
                  className="my-1.5 h-[10px] w-[5px]"
                />
              </div>
            }
            className="mb-1.5 min-w-[160px] gap-2.5 self-end font-semibold md:self-auto"
          >
            {pageData?.apply_job_button_text}
          </Button>
        </div>
        <div className="mr-5 flex items-center justify-between gap-5 md:mr-0 sm:flex-col">
          <div className="flex flex-1 gap-[9px] sm:self-stretch">
            {data?.acf?.job_details?.map((item, index) => (
              <Button
                color="gray_800"
                size="md"
                variant="outline"
                shape="round"
                leftIcon={
                  <div className="flex h-[12px] w-[8px] items-center justify-center">
                    <Img
                      src={imageSrcs[index]}
                      alt="Linkedin"
                      className="my-1 h-[12px] w-[8px]"
                    />
                  </div>
                }
                className="min-w-[108px] gap-2.5"
              >
                {item?.text}
              </Button>
            ))}
          </div>
          <div className="flex items-center gap-[13px]">
            <Heading as="h6" className="!font-semibold">
              {pageData?.view_details}
            </Heading>
            <Img
              src="images/img_vector_black_900_03.svg"
              alt="Details Image"
              className="mb-1.5 h-[6px] self-end"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
