import { Link } from "react-router-dom";
import {
  Button,
  Img,
  Heading,
  Text,
  BreadcrumbLink,
  Breadcrumb,
  BreadcrumbItem,
} from "../../components";
import UserProfileImage from "../../components/UserProfileImage";
import React, { Suspense } from "react";

export default function ProductPageSection({ data }) {
  const [quantity, setQuantity] = React.useState(1);
  const [currentImage, setCurrentImage] = React.useState(0);
  console.log("currentImage", currentImage);

  return (
    <>
      {/* product page section */}
      <div className="flex justify-start w-full container-xs sm:ml-3">
        <Breadcrumb
          separator={
            <Text className="h-[22px] w-[6px] text-[14px] font-semibold !text-black-900_66_01">
              /
            </Text>
          }
          className="flex flex-wrap gap-5"
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="#">
              <Heading
                size="headings"
                as="h1"
                className="!text-black-900_66_01"
              >
                <Link to={"/"}>Home</Link>
              </Heading>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="#">
              <Heading
                size="headings"
                as="h2"
                className="!text-black-900_66_01"
              >
                <Link to={"/catalogue"}>Catalogue</Link>
              </Heading>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#">
              <Heading size="headings" as="h3">
                {data?.name}
              </Heading>
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div className="flex justify-center sm:hidden">
        <div className="container-xs flex justify-center md:px-5">
          <div className="flex flex-col self-start md:w-full md:gap-[79px] md:self-auto sm:gap-[53px]">
            <div className="mr-[30px] flex flex-col gap-1.5 md:mr-0 md:flex-row sm:flex-col">
              <Suspense fallback={<div>Loading feed...</div>}>
                {data?.images?.map((image, index) => (
                  <UserProfileImage
                    image={image?.src}
                    key={"productList" + index}
                    className={`rounded-[5px] w-[104px] border-2 border-solid ${
                      currentImage === index
                        ? "border-deep_orange-a400 "
                        : "border-french_grey"
                    } bg-white`}
                  />
                ))}
                {data?.acf?.product_video && (
                  <div
                    className={`rounded-[5px] w-[104px] relative border-2 border-solid flex flex-col items-center px-2.5 py-3 flex-1 custom-overlay ${
                      currentImage === data?.images?.length
                        ? "border-deep_orange-a400 "
                        : "border-french_grey"
                    }`}
                  >
                    <video
                      src={data?.acf?.product_video}
                      className="h-[80px] w-[80px] object-cover"
                    />
                    <Img
                      src="/images/playbutton.svg"
                      className="absolute bottom-7"
                    />
                  </div>
                )}
              </Suspense>
            </div>
          </div>
          <div className="flex  w-full items-center md:flex-col">
            <div className="flex flex-1 items-center justify-center md:flex-col md:self-stretch">
              <Img
                src="/images/img_arrow_right_black.svg"
                alt="Black Right Arrow"
                className="ml-3.5 h-[20px] md:ml-0 md:w-full pr-5"
                onClick={() => {
                  if (currentImage > 0) {
                    setCurrentImage(currentImage - 1);
                  }
                }}
              />
              {data?.images?.[currentImage]?.src && (
                <Img
                  src={data?.images?.[currentImage]?.src}
                  alt="Featured Image"
                  className="ml-2.5 h-[500px] w-[550px] object-contain md:ml-0 md:w-full"
                />
              )}
              {data?.acf?.product_video &&
                currentImage === data?.images?.length && (
                  <video
                    src={data?.acf?.product_video}
                    autoPlay
                    className=" h-[500px] w-[550px] object-contain md:ml-0 md:w-full "
                  />
                )}

              <Img
                src="/images/img_arrow_right.svg"
                alt="Right Arrow"
                className="h-[20px] md:w-full pl-5"
                onClick={() => {
                  if (
                    currentImage + 1 != data?.images?.length &&
                    data?.images?.length != currentImage
                  ) {
                    setCurrentImage(currentImage + 1);
                  } else {
                    if (data?.acf?.product_video) {
                      setCurrentImage(data?.images?.length);
                    }
                  }
                }}
              />
            </div>
            <div className="flex w-[30%] flex-col items-end md:w-full">
              <div className="flex w-[86%] flex-col md:w-full">
                <Text
                  size="text4xl"
                  as="p"
                  className="!font-marlidedisplay leading-[65px] tracking-[0.60px]"
                >
                  {data?.name}
                </Text>
                <Text as="p" className="mt-5 leading-6 !text-gray-800">
                  <div
                    dangerouslySetInnerHTML={{ __html: data?.description }}
                  />
                </Text>
                <Img
                  src="/images/img_color_selection.svg"
                  alt="Color Selector"
                  className="mt-7 h-[28px] w-[44%] object-contain"
                />
                <div className="mt-[30px] flex gap-3.5">
                  <div className="flex w-[40%] items-center justify-center gap-5 rounded border border-solid border-french_grey p-2.5">
                    <Img
                      src="/images/minus.svg"
                      alt="Increment Button"
                      className="h-[14px] w-[14px]"
                      onClick={(event) => {
                        event.stopPropagation();
                        setQuantity((quantity) =>
                          quantity < 1 ? 0 : quantity - 1
                        );
                      }}
                    />
                    <Heading
                      size="headinglg"
                      as="h4"
                      className="tracking-[0.36px] !text-space_cadet"
                    >
                      {quantity}
                    </Heading>
                    <Img
                      src="/images/img_plus.svg"
                      alt="Increment Button"
                      className="h-[14px] w-[14px]"
                      onClick={(event) => {
                        event.stopPropagation();
                        setQuantity((quantity) => quantity + 1);
                      }}
                    />
                  </div>
                  <Button
                    shape="round"
                    rightIcon={
                      <div className="flex items-center justify-center">
                        <Img
                          src="/images/img_cart.svg"
                          alt="Cart"
                          className="h-[20px] w-[20px]"
                        />
                      </div>
                    }
                    className="min-w-[172px] gap-2.5 font-semibold"
                  >
                    Add To Quote
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden sm:flex justify-center">
        <div className="flex flex-1 items-center justify-center md:flex-col md:self-stretch">
          <div className="flex justify-center relative mb-10">
            <Img
              src="/images/img_color_selection.svg"
              alt="Color Selector"
              className="w-[30%] object-contain rotate-90 absolute left-[-27px] top-[50%]"
            />
            {data?.images?.[currentImage]?.src && (
              <Img
                src={data?.images?.[currentImage]?.src}
                alt="Featured Image"
                className="ml-2.5 h-[500px] w-[550px] object-contain md:ml-0 md:w-full"
              />
            )}
            {data?.acf?.product_video &&
              currentImage === data?.images?.length && (
                <video
                  src={data?.acf?.product_video}
                  autoPlay
                  className=" h-[500px] w-[550px] object-contain md:ml-0 md:w-full "
                />
              )}
          </div>

          <div className="flex justify-around w-[80%]">
            <Img
              src="/images/img_arrow_right_black.svg"
              alt="Black Right Arrow"
              className="ml-3.5 h-[20px] sm:w-[20px] md:ml-0 md:w-full"
              onClick={() => {
                if (currentImage > 0) {
                  setCurrentImage(currentImage - 1);
                }
              }}
            />
            <Img
              src="/images/img_arrow_right.svg"
              alt="Right Arrow"
              className="h-[20px] sm:w-[20px] md:w-full"
              onClick={() => {
                if (
                  currentImage + 1 != data?.images?.length &&
                  data?.images?.length != currentImage
                ) {
                  setCurrentImage(currentImage + 1);
                } else {
                  if (data?.acf?.product_video) {
                    setCurrentImage(data?.images?.length);
                  }
                }
              }}
            />
          </div>
          <div className="flex flex-row mt-5 mb-10">
            <div className=" flex justify-between gap-1">
              <Suspense fallback={<div>Loading feed...</div>}>
                {data?.images?.map((image, index) => (
                  <UserProfileImage
                    image={image?.src}
                    key={"productList" + index}
                    className={`rounded-[5px] h-[86px] w-[86px] border border-solid ${
                      currentImage === index
                        ? "border-deep_orange-a400 "
                        : "border-french_grey"
                    } bg-white`}
                  />
                ))}
                {data?.acf?.product_video && (
                  <div
                    className={`rounded-[5px] w-[104px] relative border-2 border-solid flex flex-col items-center px-2.5 py-3 flex-1 custom-overlay ${
                      currentImage === data?.images?.length
                        ? "border-deep_orange-a400 "
                        : "border-french_grey"
                    }`}
                  >
                    <video
                      src={data?.acf?.product_video}
                      className="h-full w-full object-cover"
                    />
                    <Img
                      src="/images/playbutton.svg"
                      className="absolute bottom-5"
                    />
                  </div>
                )}
              </Suspense>
            </div>
          </div>
          <div className=" flex gap-3.5 w-full shadow">
            <div className="flex w-[40%] items-center justify-center gap-5 rounded  p-4">
              <Img
                src="/images/minus.svg"
                alt="Increment Button"
                className="h-[14px] w-[14px]"
                onClick={(event) => {
                  event.stopPropagation();
                  setQuantity((quantity) => (quantity < 1 ? 0 : quantity - 1));
                }}
              />
              <Heading
                size="headinglg"
                as="h4"
                className="tracking-[0.36px] !text-space_cadet px-4"
              >
                {quantity}
              </Heading>
              <Img
                src="/images/img_plus.svg"
                alt="Increment Button"
                className="h-[14px] w-[14px]"
                onClick={(event) => {
                  event.stopPropagation();
                  setQuantity((quantity) => quantity + 1);
                }}
              />
            </div>
            <Button
              rightIcon={
                <div className="flex items-center justify-center ">
                  <Img
                    src="/images/img_cart.svg"
                    alt="Cart"
                    className="h-[18px] w-[18px]"
                  />
                </div>
              }
              className="min-w-[172px] h-auto text-13 w-[60%] gap-2.5 font-semibold "
            >
              Add To Quote
            </Button>
          </div>
          <div className="container-xs px-3 mt-6">
            <Text
              size="text4xl"
              as="p"
              className="!font-marlidedisplay leading-[65px] tracking-[0.60px]"
            >
              {data?.name}
            </Text>
            <Text as="p" className="mt-5 leading-6 !text-gray-800 text-14">
              <div dangerouslySetInnerHTML={{ __html: data?.description }} />
            </Text>
          </div>
        </div>
      </div>
    </>
  );
}
