import { Link, useNavigate } from "react-router-dom";
import { Img, Text, Button, Input } from "./..";
import React, { useEffect, useState } from "react";

export default function Footer({ data, ...props }) {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  // Show the button when the user scrolls down 300px
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top of the page when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <footer
      {...props}
      className={`${props.className} flex flex-col py-3 bg-gray-300`}
    >
      <div className="container-xs mt-4 flex flex-col gap-[26px] md:px-5">
        <div className="flex items-center justify-between gap-5 sm:flex-col sm:items-start">
          <Img
            src={data?.logo}
            alt="Footer Logo"
            className="h-[34px] w-[134px] object-contain"
          />
          <div className="flex sm:w-full w-[30%]">
            <Input
              shape="square"
              type="text"
              name="newsletter"
              placeholder={`Join our email list for new updates`}
              className="w-full sm:w-[90%] bg-white border-[0.5px] border-foundation-dark-lighter"
            />
            <Button
              color="black_900_03"
              size="lg"
              className="min-w-[74px] h-[50px] sm:w-[10%] rounded-br rounded-tr font-semibold"
            >
              Send
            </Button>
          </div>
        </div>
        <div>
          <div className="flex flex-col gap-3 ">
            <div className="h-px bg-gray-400_01" />
            <div className="flex justify-between gap-5 md:flex-col sm:flex-col-reverse">
              <div className="flex w-[42%] flex-wrap justify-between gap-5 md:w-full">
                <Text
                  size="texts"
                  as="p"
                  className="!text-black-900_01 sm:w-[44%]"
                >
                  {data?.copyright_text}
                </Text>
                <Link
                  className="sm:w-[44%]"
                  to="/careers"
                  onClick={scrollToTop}
                >
                  <Text
                    size="texts"
                    as="p"
                    className="!text-black-900_01 hover:!text-deep_orange-a400 transition"
                  >
                    Careers
                  </Text>
                </Link>
                <Link
                  className="sm:w-[44%]"
                  to="/terms-of-use"
                  onClick={scrollToTop}
                >
                  <Text
                    size="texts"
                    as="p"
                    className="!text-black-900_01 hover:!text-deep_orange-a400 transition"
                  >
                    Terms of services
                  </Text>
                </Link>
                <Link
                  className="sm:w-[44%]"
                  to="/privacy-policy"
                  onClick={scrollToTop}
                >
                  <Text
                    size="texts"
                    as="p"
                    className="!text-black-900_01 hover:!text-deep_orange-a400 transition"
                  >
                    Privacy policy
                  </Text>
                </Link>
              </div>
              <div className="ml-[34px] my-4 flex-1 justify-start gap-3.5 sm:ml-0 sm:self-stretch sm:w-full sm:flex hidden">
                {data?.social_icons?.map((icon, index) => (
                  <Img
                    src={icon?.icon}
                    className="h-[18px] w-[18px] shake-on-hover transition"
                  />
                ))}
              </div>
              <div className="flex w-[38%] items-center justify-center md:w-full sm:flex-row sm:flex-wrap sm:w-full sm:justify-between">
                {data?.contact_details?.map((item, index) => (
                  <div className="flex flex-1 items-center justify-center gap-2.5 sm:self-stretch sm:w-1/2 sm:justify-end">
                    <Img
                      src={item?.icon}
                      alt="Email Icon"
                      className="h-[14px] w-[14px]"
                    />
                    <Text as="p" className="!text-black-900_01">
                      {item?.text}
                    </Text>
                  </div>
                ))}

                <div className="ml-[34px] flex flex-1 justify-center gap-3.5 sm:ml-0 sm:self-stretch sm:w-full sm:hidden ">
                  {data?.social_icons?.map((icon, index) => (
                    <Img
                      src={icon?.icon}
                      className="h-[18px] w-[18px] shake-on-hover transition"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isVisible && (
        <div
          onClick={scrollToTop}
          className="fixed bottom-5 right-5 flex flex-col items-center justify-center  bg-blue_gray-200 p-3 cursor-pointer transition hover:bg-blue_gray-300"
        >
          <Img
            src={data?.bottom_to_top_icon}
            alt="Scroll to Top"
            className="h-[22px] w-[22px]"
          />
        </div>
      )}
    </footer>
  );
}
