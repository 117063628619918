import {
  Img,
  Button,
  TextArea,
  Text,
  SelectBox,
  Input,
} from "../../components";
import React, { useEffect, useState } from "react";
import Select from "react-select";

export default function JobApplicationSection({
  jobsData,
  selectedJobId,
  formRef,
}) {
  const [selectedJob, setSelectedJob] = useState(selectedJobId);

  useEffect(() => {
    const findJob = jobsData?.find((item) => item.id === selectedJobId);
    console.log("selectedJob", findJob);
    setSelectedJob({
      label: findJob?.title?.rendered, // Display job title
      value: findJob?.id, // Unique identifier for the job
    });
  }, [selectedJobId]);

  const jobOptions = jobsData.map((job) => ({
    label: job.title.rendered, // Display job title
    value: job.id, // Unique identifier for the job
  }));

  return (
    <>
      {/* job application section */}
      <div
        ref={formRef}
        className="relative z-[1] h-[732px] content-center md:h-auto"
      >
        <div className="mx-auto flex flex-1 flex-col items-center bg-gray-100 pt-20 md:py-5">
          <div className="container-xs mb-[78px] flex flex-col items-center px-14 md:px-5">
            <Text
              size="text4xl"
              as="p"
              className="!font-marlidedisplay tracking-[0.60px]"
            >
              Apply for a job
            </Text>
            <div className="mr-3 mt-11 flex w-[58%] justify-center gap-2.5 md:mr-0 md:w-full md:flex-col">
              <Input
                shape="square"
                type="text"
                name="First Name Field"
                placeholder={`First name`}
                className="w-full border-[0.5px] border-foundation-dark-lighter"
              />
              <Input
                shape="square"
                type="text"
                name="Last Name Field"
                placeholder={`Last name`}
                className="w-full border-[0.5px] border-foundation-dark-lighter"
              />
            </div>
            <div className="mr-3 mt-3 flex w-[58%] justify-center gap-2.5 md:mr-0 md:w-full md:flex-col">
              <Input
                shape="square"
                type="number"
                name="Mobile Field"
                placeholder={`Mobile number`}
                className="w-full border-[0.5px] border-foundation-dark-lighter"
              />
              <Input
                shape="square"
                type="email"
                name="Email Field"
                placeholder={`Email address`}
                className="w-full border-[0.5px] border-foundation-dark-lighter"
              />
            </div>
            <Select
              value={selectedJob ?? ""}
              onChange={(e) => setSelectedJob(e)}
              options={jobOptions}
              name="Position Dropdown"
              placeholder={`Position`}
              className="mr-3 mt-3 w-[58%] sm:w-full gap-4 border-[0.5px] border-solid border-foundation-dark-lighter md:mr-0"
            />
            {/* <SelectBox
              shape="square"
              indicator={
                <Img
                  src="images/img_down_1.svg"
                  alt="Down 1"
                  className="h-[18px] w-[18px]"
                />
              }
              name="Position Dropdown"
              value={String(selectedJob)}
              onChange={(e) => setSelectedJob(e.value)}
              placeholder={`Position`}
              options={jobOptions}
              className="mr-3 mt-3 w-[58%] sm:w-full gap-4 border-[0.5px] border-solid border-foundation-dark-lighter md:mr-0"
            /> */}
            <div className="mr-3 mt-3 flex w-[58%] justify-center gap-2.5 md:mr-0 md:w-full md:flex-col">
              <TextArea
                shape="square"
                name="Message Field"
                placeholder={`Message`}
                className="w-full  !border-foundation-dark-lighter text-foundation-dark-light__hover"
              />
            </div>
            <div className="mr-3 sm:w-full w-[58%] sm:mx-0 mb-3 mt-3 items-center  ">
              <div className="w-auto border-[0.5px] h-[45px] border-foundation-dark-lighter inline-flex p-3">
                <Img
                  src="images/img_attach_icon.svg"
                  alt="Attach Icon"
                  className="h-[20px] w-[20px]"
                />
                <Text size="texts" as="p">
                  Attach Resume
                </Text>
              </div>
            </div>
            <div className="relative mr-3 sm:p-0 z-[2]  flex flex-1 flex-col md:mr-0 md:w-full md:flex-col  md:gap-[67px] md:self-stretch md:px-5 sm:gap-[45px] sm:self-auto">
              <Button
                shape="round"
                className="min-w-[684px] sm:min-w-full font-semibold"
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
        {/* <div className="  flex flex-1 items-center md:relative md:flex-col">
          <Img
            src="images/img_pictograms_beige_04_630x384.png"
            alt="Pictogram Image"
            className="h-[630px] w-[26%] object-contain md:w-full"
          />
        </div> */}
      </div>
    </>
  );
}
