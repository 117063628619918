import { Helmet } from "react-helmet";
import { Text, Img, Button } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import React, { useEffect, useState } from "react";
import { apiCall } from "utils";
import { API } from "constant";
import ScrollAnimation from "react-animate-on-scroll";
import { useNavigate } from "react-router-dom";

export default function AboutUsPage() {
  const [headerData, setHeaderData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchThemeOption();
    fetchPageData();
  }, []);

  useEffect(() => {
    if (headerData && pageData) {
      setLoading(false);
    }
  }, [headerData, pageData]);

  const fetchThemeOption = async () => {
    try {
      const response = await apiCall("GET", API.themeOptions, null, {}, true); // Pass true if auth is required
      setHeaderData(response);
    } catch (err) {
      setError(err.message || "Error fetching data");
    }
  };

  const fetchPageData = async () => {
    try {
      const response = await apiCall("GET", API.aboutUsPage, null, {}, true); // Pass true if auth is required
      setPageData(response?.[0]?.acf);
    } catch (err) {
      setError(err.message || "Error fetching data");
    }
  };

  return loading ? (
    <div className="w-full flex justify-center items-center h-screen">
      Data is Loading
    </div>
  ) : (
    <>
      <Helmet>
        <title>About Us - Forevent</title>
        <meta
          name="description"
          content="Learn about Forevent's mission and team. We're looking for passionate individuals to join us. Read about our values and career opportunities."
        />
      </Helmet>
      <div className="w-full bg-white">
        <div className="container-xs md:px-5">
          <Header className="gap-5" data={headerData} />
        </div>
        <Text
          size="text4xl"
          as="p"
          className=" m-auto mb-8 w-max !font-marlidedisplay tracking-[0.60px] sm:mb-8 mt-8"
        >
          {pageData?.page_title}
        </Text>
        <div className="relative">
          <div className="container-xs  flex justify-center md:px-5">
            <div className=" mt-[30px] mb-[50px] sm:mt-0">
              <ScrollAnimation
                animateIn="fadeInLeft"
                animateOut="fadeOutLeft"
                className="absolute right-0 bottom-0 top-[-110px]"
              >
                <Img
                  src={pageData?.right_background_image?.url}
                  alt="Pictogram Image"
                  className=" z-0 w-[100%]  object-contain  sm:w-[100%] sm:m-0 sm:top-72"
                />
              </ScrollAnimation>
              <div className="flex gap-10 sm:flex-col">
                <Img
                  src={pageData?.left_image?.url}
                  alt="Product Image"
                  className="m-auto  w-[50%] rounded object-contain sm:w-[100%]"
                />
                <div className=" w-[50%] sm:w-full flex mt-3 flex-col items-end gap-8 z-10 sm:mt-0">
                  <Text
                    as="p"
                    className=" !text-gray-800 md:w-full md:px-5 text-15 sm:p-0"
                  >
                    {pageData?.main_content}
                  </Text>
                  <div className="flex flex-col items-start gap-1.5 md:w-full md:px-5 sm:p-0">
                    <Text
                      size="textxl"
                      as="p"
                      className="!font-marlidedisplay tracking-[0.30px] mb-3 sm:text-30"
                    >
                      {pageData?.who_we_are_title}
                    </Text>
                    <Text as="p" className="w-full !text-gray-800 text-15 mb-4">
                      {pageData?.who_we_are_content}
                    </Text>
                  </div>
                  <div className="flexflex-col items-start  md:w-full md:px-5 sm:p-0">
                    <Text
                      size="textxl"
                      as="p"
                      className="!font-marlidedisplay tracking-[0.30px] mb-3 sm:text-30"
                    >
                      {pageData?.our_mission_title}
                    </Text>
                    <Text as="p" className="w-full  !text-gray-800 text-15">
                      {pageData?.our_mission_content}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-xs  my-auto flex justify-center md:px-5 sm:hidden">
          <div className=" mb-12 w-full rounded bg-[url(/public/images/img_group_527.png)] bg-cover bg-no-repeat md:h-auto">
            <div>
              <div className="flex items-center justify-end md:flex-col">
                <div className="flex w-[56%]  flex-col items-start md:w-full">
                  <Text
                    size="text3xl"
                    as="p"
                    className="!font-marlidedisplay text-45 tracking-[0.45px] mb-2 !text-gray-300"
                  >
                    {pageData?.join_team_title}
                  </Text>
                  <Text as="p" className="w-[90%]  !text-gray-300 md:w-full">
                    {pageData?.join_team_content}
                  </Text>
                </div>
                <div className="flex items-center sm:flex-col">
                  <Button
                    shape="round"
                    rightIcon={
                      <div className="flex h-[10px] w-[4px] items-center justify-center">
                        <Img
                          src="images/img_arrowright_white.svg"
                          alt="Arrow Right"
                          className="my-1.5 h-[20px] w-[10px]"
                        />
                      </div>
                    }
                    className="relative z-[1] min-w-[208px] gap-2.5 font-semibold"
                  >
                    {pageData?.applu_button_text}
                  </Button>
                  <Img
                    src="images/img_pictograms_blue_10.png"
                    alt="Team Image"
                    className="relative ml-[-26px] h-[194px] w-[194px] opacity-30 rounded-sm object-cover sm:ml-0 sm:w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-xs  my-auto sm:flex justify-center md:px-5 hidden">
          <div className=" mb-12 w-full rounded bg-[url(/public/images/img_group_527.png)] bg-cover bg-no-repeat md:h-auto sm:p-7 sm:relative">
            <div>
              <div className="flex items-center justify-end md:flex-col">
                <div className="flex w-[56%]  flex-col items-start md:w-full">
                  <Text
                    size="text3xl"
                    as="p"
                    className="!font-marlidedisplay text-45 tracking-[0.45px] !text-gray-300 mb-4"
                  >
                    {pageData?.join_team_title}
                  </Text>
                  <Text
                    as="p"
                    className="w-[90%]  !text-gray-300 md:w-full mb-8 leading-25"
                  >
                    {pageData?.join_team_content}
                  </Text>
                </div>
                <div className="flex items-center sm:flex-col">
                  <Button
                    shape="round"
                    onClick={() => navigate("/careers")}
                    rightIcon={
                      <div className="flex h-[10px] w-[7px] items-center justify-center">
                        <Img
                          src="images/img_arrowright_white.svg"
                          alt="Arrow Right"
                        />
                      </div>
                    }
                    className="relative z-[1] min-w-[208px] gap-2.5 font-normal"
                  >
                    {pageData?.applu_button_text}
                  </Button>
                </div>
              </div>
            </div>
            <Img
              src="images/img_pictograms_blue_10.png"
              alt="Team Image"
              className="absolute w-[35%] right-0 bottom-0 ml-[-26px] opacity-15 rounded-sm object-cover "
            />
          </div>
        </div>
        <Footer data={headerData?.header?.footer_settings} />
      </div>
    </>
  );
}
