import { Helmet } from "react-helmet";
import {
  Text,
  Img,
  Heading,
  Button,
  CheckBox,
  TextArea,
  SelectBox,
  DatePicker,
  Input,
} from "../../components";
import Footer from "../../components/Footer";
import ProductDetail from "../../components/ProductDetail";
import React, { useEffect, useState } from "react";
import { API } from "constant";
import { apiCall } from "utils";
import Header from "components/Header";
import ScrollAnimation from "react-animate-on-scroll";

const dropDownOptions = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" },
];

export default function EnquirewithproductsPage() {
  const [headerData, setHeaderData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchThemeOption();
    fetchPageData();
  }, []);

  useEffect(() => {
    if (headerData && pageData) {
      setLoading(false);
    }
  }, [headerData, pageData]);

  const fetchThemeOption = async () => {
    try {
      const response = await apiCall("GET", API.themeOptions, null, {}, true); // Pass true if auth is required
      setHeaderData(response);
    } catch (err) {
      setError(err.message || "Error fetching data");
    }
  };

  const fetchPageData = async () => {
    try {
      const response = await apiCall("GET", API.careersPage, null, {}, true); // Pass true if auth is required
      setPageData(response?.[0]?.acf);
    } catch (err) {
      setError(err.message || "Error fetching data");
    }
  };
  return (
    <>
      <Helmet>
        <title>Product Enquiry - Forevent</title>
        <meta
          name="description"
          content="Submit your enquiry for selected products like the Bohemian Bliss Armchair and Gilded Harmony Chair. Contact us for your event needs and subscribe for updates."
        />
      </Helmet>
      <div className="flex w-full flex-col gap-8 bg-white">
        <div className=" flex justify-center md:px-5 border-gray-300 border-b">
          <div className="flex container-xs  w-full flex-col items-center  sm:gap-[116px]">
            <Header data={headerData} className="gap-5 self-stretch py-8 " />
          </div>
        </div>
        <Text
          size="text4xl"
          as="p"
          className=" m-auto w-max !font-marlidedisplay tracking-[0.60px]"
        >
          Enquire
        </Text>
        <div className="relative ">
          <ScrollAnimation
            animateIn="fadeInLeft"
            animateOut="fadeOutLeft"
            className="absolute bottom-[-1.95px] right-px m-auto h-[620px] w-[28%] object-contain"
          >
            <Img
              src="images/img_pictograms_beige_02_gray_300_02.png"
              alt="Pictogram One"
              className=""
            />
          </ScrollAnimation>
          <div className="m-auto flex flex-1 justify-center gap-6 px-14 md:relative md:flex-col md:px-5">
            <div className="flex w-[52%] flex-col gap-6 md:w-full">
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-3">
                  <div className="flex gap-2.5 md:flex-col">
                    <Input
                      shape="square"
                      type="text"
                      name="First Name Input"
                      placeholder={`First name`}
                      className="w-full border-[0.5px] border-foundation-dark-lighter"
                    />
                    <Input
                      shape="square"
                      type="text"
                      name="Last Name Input"
                      placeholder={`Last name`}
                      className="w-full border-[0.5px] border-foundation-dark-lighter"
                    />
                  </div>
                  <div className="flex gap-2.5 md:flex-col">
                    <Input
                      shape="square"
                      type="number"
                      name="Mobile Input"
                      placeholder={`Mobile number`}
                      className="w-full border-[0.5px] border-foundation-dark-lighter"
                    />
                    <Input
                      shape="square"
                      type="email"
                      name="Email Input"
                      placeholder={`Email address`}
                      className="w-full border-[0.5px] border-foundation-dark-lighter"
                    />
                  </div>
                </div>
                <Input
                  shape="square"
                  type="text"
                  name="Company Input"
                  placeholder={`Company name`}
                  className="border-[0.5px] border-foundation-dark-lighter"
                />
                <div className="flex gap-3 md:flex-col">
                  <DatePicker
                    name="Start Date Picker"
                    placeholder={`Event start date`}
                    className="flex w-full gap-[34px] border-[0.5px] border-solid border-foundation-dark-lighter bg-white px-5 py-3 text-[16px] text-foundation-dark-light__hover"
                  />
                  <DatePicker
                    name="End Date Picker"
                    placeholder={`Event end date`}
                    className="flex w-full gap-[34px] border-[0.5px] border-solid border-foundation-dark-lighter bg-white px-5 py-3 text-[16px] text-foundation-dark-light__hover"
                  />
                  <SelectBox
                    shape="square"
                    indicator={
                      <Img
                        src="images/img_down_1.svg"
                        alt="Down 1"
                        className="h-[18px] w-[18px]"
                      />
                    }
                    name="Location Dropdown"
                    placeholder={`Event location`}
                    options={dropDownOptions}
                    className="w-full gap-4 border-[0.5px] border-solid border-foundation-dark-lighter"
                  />
                </div>
                <TextArea
                  shape="square"
                  name="Message TextArea"
                  placeholder={`Message`}
                  className="!border-foundation-dark-lighter text-foundation-dark-light__hover"
                />
              </div>
              <div className="py-2.5">
                <div className="flex gap-[52px] sm:flex-col">
                  <CheckBox
                    name="Catalogue Checkbox"
                    label="Send me a PDF catalogue"
                    id="CatalogueCheckbox"
                    className="gap-2.5 text-[14px] font-semibold text-black-900_03"
                  />
                  <CheckBox
                    name="Newsletter Checkbox"
                    label="Subscribe to our newsletter"
                    id="NewsletterCheckbox"
                    className="gap-2.5 text-[14px] font-semibold text-black-900_03"
                  />
                </div>
              </div>
              <a
                href="https://www.youtube.com/embed/bv8Fxk0sz7I"
                target="_blank"
              >
                <Button shape="round" className="self-stretch font-semibold">
                  Submit
                </Button>
              </a>
            </div>
            <div className="w-[28%] md:w-full">
              <div className="flex flex-col items-start gap-7 rounded border border-solid border-gray-300 bg-white px-5 py-3.5">
                <Heading as="h1">Products selected</Heading>
                <div className="mb-[70px] flex flex-col gap-[18px] self-stretch">
                  <ProductDetail />
                  <ProductDetail className=" md:ml-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer data={headerData?.header?.footer_settings} />
      </div>
    </>
  );
}
