import { Button, Img, Text, Slider } from "../../components";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export default function FurnitureShowcaseSection({ data }) {
  const [sliderState, setSliderState] = React.useState(0);
  const sliderRef = React.useRef(null);

  return (
    <>
      {/* furniture showcase section */}
      <div className="mt-10 flex flex-col items-center gap-12">
        <div className="container-xs flex flex-col items-center px-14 md:px-5">
          <div className="relative py-32 sm:py-28 w-[58%] sm:w-full">
            <ScrollAnimation animateIn="fadeInLeft" animateOut="fadeOutRight">
              <Img
                src={data?.title_background_image}
                alt="Pictogram Image"
                className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[372px] w-[372px]"
              />
            </ScrollAnimation>
            <Text
              size="text3xl"
              as="p"
              className="absolute bottom-0 left-0 right-0 top-0 m-auto h-max flex-1 text-center !font-marlidedisplay leading-[50px] tracking-[0.45px] sm:!text-32"
            >
              {data?.title}
            </Text>
          </div>
        </div>
        <div className="relative h-[260px] content-center self-stretch md:h-auto">
          <div className="absolute bottom-0 z-0 left-0 right-0 top-0 m-auto flex h-max flex-1 justify-between gap-5 sm:!top-full sm:m-0 sm:justify-evenly">
            <Button
              color="gray_300_01"
              size="4xl"
              shape="round"
              onClick={() => {
                sliderRef?.current?.slidePrev();
              }}
              className="w-[68px] rotate-[-180deg] !rounded-sm border border-solid border-gray-200 sm:py-5 sm:bg-transparent sm:border-none"
            >
              <Img src="images/img_arrow_right.svg" />
            </Button>
            <Button
              color="gray_300_01"
              size="4xl"
              shape="round"
              onClick={() => {
                sliderRef?.current?.slideNext();
              }}
              className="w-[68px] !rounded-sm border border-solid border-gray-200 sm:py-5 sm:bg-transparent sm:border-none"
            >
              <Img src="images/img_arrow_right.svg" />
            </Button>
          </div>
          <div className="mx-auto flex w-full justify-center px-14 md:px-5">
            <div className="mx-auto flex w-[94%] gap-[22px] md:mx-0 md:w-full md:flex-col">
              <Slider
                autoPlay={false}
                responsive={{
                  0: { items: 1 },
                  551: { items: 1 },
                  1051: { items: 3 },
                }}
                infinite={false}
                disableDotsControls
                onSlideChanged={(e) => {
                  setSliderState(e?.item);
                }}
                ref={sliderRef}
                items={[...Array(9)].map(() => (
                  <React.Fragment key={Math.random()}>
                    <div className="p-[11px]">
                      {/* Apply hover styles to the container */}
                      <div className="group relative flex items-center rounded-sm border border-solid border-gray-200 transition duration-300 ease-in-out transform hover:scale-110 hover:bg-[#AFB8CE]">
                        {/* Text remains unaffected by hover */}
                        <Text
                          size="text2xl"
                          as="p"
                          className="ml-8 !font-marlidedisplay tracking-[0.32px] transition duration-300 ease-in-out"
                        >
                          Seating
                        </Text>
                        {/* Image will scale on hover using group-hover */}
                        <Img
                          src="images/chair_bg.png"
                          alt="Chair Image"
                          className="h-[200px] w-[200px] rounded-sm object-cover transition duration-300 ease-in-out"
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
