import { Img, Text } from "../../components";
import React from "react";

export default function MeetTheTeamSection({ data }) {
  return (
    <>
      {/* meet the team section */}
      <Text
        size="text4xl"
        as="p"
        className="!font-marlidedisplay tracking-[0.60px] sm:block hidden sm:text-40"
      >
        {data?.title}
      </Text>
      <div className="relative ml-[-365px] w-full flex flex-1 items-center justify-center pl-14 md:ml-0 md:flex-col md:self-stretch md:px-5 sm:flex-col-reverse">
        <div className="flex w-[44%] flex-col items-start gap-6 md:w-full">
          <Text
            size="text4xl"
            as="p"
            className="!font-marlidedisplay tracking-[0.60px] sm:hidden"
          >
            {data?.title}
          </Text>
          <Text as="p" className="w-[90%] leading-6 !text-gray-800 md:w-full">
            <div dangerouslySetInnerHTML={{ __html: data?.content }} />
          </Text>
        </div>
        <Img
          src={data?.content_image}
          alt="Product Image"
          className="h-[420px] w-[44%] rounded object-contain md:w-full sm:h-[252px] sm:mt-10 sm:mb-6"
        />
      </div>
    </>
  );
}
