import { Helmet } from "react-helmet";
import { Button, Img, Heading, Text } from "../../components";
import ProductDetails from "../../components/ProductDetails";
import React from "react";

export default function ViewCartPage() {
  return (
    <>
      <Helmet>
        <title>View Your Event Furniture Cart - Request A Quote</title>
        <meta
          name="description"
          content="Review your selected event furniture items, including the Bohemian Bliss and Gilded Harmony chairs. Ready to proceed? Request a quote directly from your cart."
        />
      </Helmet>
      <div className="w-full bg-white">
        <div className="relative h-[900px] content-center md:h-auto">
          <div className="mx-auto flex flex-1 flex-col items-center gap-14 sm:gap-7">
            <header className="flex flex-col items-center gap-[34px] self-stretch">
              <div className="container-xs md:px-5">
                <div className="flex items-start justify-between gap-5 md:flex-col">
                  <Img
                    src="images/img_forevent_beige_1.png"
                    alt="Event Image"
                    className="h-[40px] w-[12%] self-center object-contain md:w-full"
                  />
                  <ul className="!mb-1.5 flex flex-wrap gap-[46px] self-end md:self-auto">
                    <li>
                      <a href="#">
                        <Text as="p">Catalogue</Text>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <Text as="p">Inspiration</Text>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <Text as="p">About us</Text>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <Text as="p">Enquire</Text>
                      </a>
                    </li>
                  </ul>
                  <div className="flex w-[4%] items-start justify-center gap-1.5 md:w-full">
                    <div className="mb-2 flex w-[24px] flex-col items-center justify-center rounded-[12px] bg-deep_orange-a400_e1">
                      <Heading size="headings" as="p" className="!font-bold !text-white">
                        2
                      </Heading>
                    </div>
                    <a href="#">
                      <Img
                        src="images/img_shopping_cart_black_900_03.svg"
                        alt="Cart Icon"
                        className="h-[24px] w-[24px] self-end"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="h-px w-full self-stretch bg-french_grey" />
            </header>
            <div className="container-xs md:px-5">
              <div className="flex items-center md:flex-col">
                <div className="flex flex-1 items-center md:flex-col md:self-stretch">
                  <div className="flex w-[30%] flex-col gap-[106px] self-start md:w-full md:gap-[79px] md:self-auto sm:gap-[53px]">
                    <div className="flex flex-wrap justify-center gap-1.5">
                      <Heading size="headings" as="h1" className="!text-black-900_66_01">
                        Home
                      </Heading>
                      <Heading size="headings" as="h2" className="!text-black-900_66_01">
                        /
                      </Heading>
                      <Heading size="headings" as="h3" className="!text-black-900_66_01">
                        Catalogue
                      </Heading>
                      <Heading size="headings" as="h4">
                        /
                      </Heading>
                      <Heading size="headings" as="h5">
                        Bohemian Bliss Chair
                      </Heading>
                    </div>
                    <div className="flex flex-col items-start gap-1.5">
                      <div className="w-[36%] rounded-[5px] border-2 border-solid border-deep_orange-a400 bg-white px-2.5 py-3 md:w-full">
                        <Img
                          src="images/img_3_1_47_29_pm_removebg_preview.png"
                          alt="Product Main Image"
                          className="h-[80px] w-[80px] object-cover"
                        />
                      </div>
                      <div className="w-[36%] rounded-[5px] border border-solid border-french_grey bg-white p-2 md:w-full">
                        <Img
                          src="images/img_rectangle_5.png"
                          alt="Product Secondary Image"
                          className="h-[88px] w-[88px] rounded-[5px] object-cover"
                        />
                      </div>
                      <div className="w-[36%] rounded-[5px] border border-solid border-french_grey bg-white p-2 md:w-full">
                        <Img
                          src="images/img_rectangle_6.png"
                          alt="Product Tertiary Image"
                          className="h-[88px] w-[88px] rounded-[5px] object-cover"
                        />
                      </div>
                      <div className="relative h-[104px] content-center self-stretch md:h-auto">
                        <Img
                          src="images/img_rectangle_4.png"
                          alt="Stacked Image"
                          className="ml-2 h-[88px] w-[88px] rounded-[5px] object-cover md:ml-0"
                        />
                        <div className="absolute bottom-0 left-0 top-0 my-auto flex h-max flex-col items-center rounded-[5px] bg-black-900_66 p-8 sm:p-5">
                          <Img
                            src="images/img_overflow_menu.svg"
                            alt="Overflow Menu Image"
                            className="h-[40px] w-[40px]"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Img src="images/img_arrow_right.svg" alt="Right Arrow" className="h-[20px] md:w-full" />
                  <Img
                    src="images/img_image_1_686x550.png"
                    alt="Large Product Image"
                    className="ml-2.5 h-[686px] w-[58%] object-contain md:ml-0 md:w-full"
                  />
                  <Img
                    src="images/img_arrow_right_black_900_03_1.svg"
                    alt="Left Arrow"
                    className="ml-3.5 h-[20px] md:ml-0 md:w-full"
                  />
                </div>
                <div className="flex w-[22%] flex-col md:w-full">
                  <Text size="text4xl" as="p" className="!font-marlidedisplay leading-[65px] tracking-[0.60px]">
                    Bohemian Bliss Armchair
                  </Text>
                  <Text as="p" className="mt-5 leading-6 !text-gray-800">
                    The gently curved lines accentuated by sewn details are kind to your body and pleasant to look at.
                    Also, there’s a tilt and height-adjusting mechanism that’s built to outlast years of ups and downs.
                  </Text>
                  <Img
                    src="images/img_color_selection.svg"
                    alt="Color Selection"
                    className="mt-7 h-[28px] w-[44%] object-contain"
                  />
                  <div className="mt-[30px] flex gap-3.5">
                    <div className="flex w-[40%] items-center justify-center gap-5 rounded border border-solid border-cool_grey p-2.5">
                      <div className="h-px w-[14px] flex-1 bg-black-900_03" />
                      <Heading size="headinglg" as="h6" className="tracking-[0.36px] !text-space_cadet">
                        1
                      </Heading>
                      <Img src="images/img_plus.svg" alt="Quantity Add" className="h-[14px] w-[14px]" />
                    </div>
                    <div className="flex flex-1 items-center justify-center gap-2.5 rounded bg-gray-900_01 p-3.5">
                      <Heading as="h6" className="!font-semibold !text-white">
                        Add To Quote
                      </Heading>
                      <Img src="images/img_cart.svg" alt="Cart Image" className="h-[18px] w-[18px]" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max flex-1 justify-center bg-black-900_66_01 py-[108px] md:py-5">
            <div className="container-xs flex justify-end md:px-5">
              <div className="w-[30%] rounded bg-white p-3.5 md:w-full">
                <div className="mb-8 flex flex-col gap-[182px] md:gap-[136px] sm:gap-[91px]">
                  <div className="relative h-[382px] content-center md:h-auto">
                    <div className="mx-auto flex flex-1 flex-col items-start">
                      <Heading as="h2" className="relative z-[1]">
                        Products selected
                      </Heading>
                      <div className="relative mt-[-8px] flex flex-col gap-[18px] self-stretch">
                        <ProductDetails />
                        <ProductDetails
                          productImage="images/img_image_148x120.png"
                          productTitle="Gilded Harmony Chair"
                          quantityText="1"
                        />
                      </div>
                    </div>
                    <Img
                      src="images/img_plus.svg"
                      alt="Add Image"
                      className="absolute right-px top-[4.50px] m-auto h-[14px] w-[14px] rounded"
                    />
                  </div>
                  <a href="https://www.youtube.com/embed/bv8Fxk0sz7I" target="_blank">
                    <Button
                      shape="round"
                      rightIcon={
                        <div className="flex h-[10px] w-[4px] items-center justify-center rounded-sm">
                          <Img
                            src="images/img_arrowright_white.svg"
                            alt="Arrow Right"
                            className="my-1.5 h-[10px] w-[4px] rounded-sm"
                          />
                        </div>
                      }
                      className="gap-2.5 self-stretch font-semibold"
                    >
                      Request A Quote
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
