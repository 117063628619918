// api.js
import axios from "axios";
import { hostUrl, KEY, SECRATE } from "constant";

// Create an Axios instance with default settings
const apiClient = axios.create({
  baseURL: hostUrl, // Replace with your base URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to conditionally add authorization headers
apiClient.interceptors.request.use(
  (config) => {
    // Return config directly if it doesn't require authorization
    if (config.requiresAuth) {
      const encodedCredentials = btoa(`${KEY}:${SECRATE}`);
      if (encodedCredentials) {
        config.headers.Authorization = `Basic ${encodedCredentials}`;
      } else {
        console.warn("Authorization required but no token found");
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle global error handling
apiClient.interceptors.response.use(
  (response) => {
    return response.data; // Return only the data from the response
  },
  (error) => {
    // Handle errors globally
    if (error.response) {
      console.error(
        `API Error: ${error.response.status} - ${error.response.data.message}`
      );
    } else if (error.request) {
      console.error("No response received from API");
    } else {
      console.error("Error setting up API request");
    }
    return Promise.reject(error);
  }
);

// Define a reusable function for API calls, with an optional requiresAuth flag
export const apiCall = async (
  method,
  url,
  data = null,
  params = {},
  requiresAuth = false
) => {
  try {
    const response = await apiClient({
      method,
      url,
      data,
      params,
      requiresAuth, // Pass the flag to the Axios config
    });
    return response;
  } catch (error) {
    throw error; // Optionally rethrow the error to handle in calling components
  }
};
