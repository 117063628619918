import {
  Img,
  Button,
  CheckBox,
  TextArea,
  SelectBox,
  DatePicker,
  Input,
  Text,
} from "../../components";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const dropDownOptions = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" },
];

export default function RequestQuoteSection({ data }) {
  return (
    <>
      {/* request quote section */}
      <div className="mt-[108px] sm:mt-[30px]">
        <div className="flex items-center bg-gray-100 py-[66px] md:flex-col md:py-5">
          <div className="mb-5 mt-3.5 flex flex-1 flex-col items-end gap-11 pl-14 pr-[58px] md:self-stretch md:px-5 ">
            <div className="sm:justify-center sm:flex sm:w-full ">
              <Text
                size="text4xl"
                as="p"
                className="mr-44 !font-marlidedisplay tracking-[0.60px] md:mr-0 sm:text-40"
              >
                {data?.title}
              </Text>
            </div>

            <div className="flex w-[68%] flex-col gap-9 md:w-full z-10">
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-3">
                  <div className="flex gap-2.5 md:flex-col sm:flex-row">
                    <Input
                      shape="square"
                      type="text"
                      name="First Name Field"
                      placeholder={`First name`}
                      className="w-full bg-white border-[0.5px] border-foundation-dark-lighter"
                    />
                    <Input
                      shape="square"
                      type="text"
                      name="Last Name Field"
                      placeholder={`Last name`}
                      className="w-full bg-white border-[0.5px] !bg-white border-foundation-dark-lighter"
                    />
                  </div>
                  <div className="flex gap-2.5 md:flex-col">
                    <Input
                      shape="square"
                      type="number"
                      name="Mobile Number Field"
                      placeholder={`Mobile number`}
                      className="w-full bg-white border-[0.5px] border-foundation-dark-lighter"
                    />
                    <Input
                      shape="square"
                      type="email"
                      name="Email Field"
                      placeholder={`Email address`}
                      className="w-full bg-white border-[0.5px] border-foundation-dark-lighter"
                    />
                  </div>
                </div>
                <Input
                  shape="square"
                  type="text"
                  name="Company Name Field"
                  placeholder={`Company name`}
                  className="bg-white border-[0.5px] border-foundation-dark-lighter placeholder:text-foundation-dark-light-hover"
                />
                <div className=" gap-3 md:flex-col sm:flex flex">
                  <div className="sm:flex sm:gap-3 hidden">
                    <DatePicker
                      name="Start Date Picker"
                      placeholder={`Event start date`}
                      className=" w-full bg-white gap-[34px] border-[0.5px] border-solid border-foundation-dark-lighter px-5 py-3 text-[16px] text-foundation-dark-light__hover"
                    />
                    <DatePicker
                      name="End Date Picker"
                      placeholder={`Event end date`}
                      className=" w-full bg-white gap-[34px] border-[0.5px] border-solid border-foundation-dark-lighter px-5 py-3 text-[16px] text-foundation-dark-light__hover"
                    />
                  </div>
                  <DatePicker
                    name="Start Date Picker"
                    placeholder={`Event start date`}
                    className=" w-full sm:hidden bg-white gap-[34px] border-[0.5px] border-solid border-foundation-dark-lighter px-5 py-3 text-[16px] text-foundation-dark-light__hover"
                  />
                  <DatePicker
                    name="End Date Picker"
                    placeholder={`Event end date`}
                    className=" w-full bg-white gap-[34px] sm:hidden border-[0.5px] border-solid border-foundation-dark-lighter px-5 py-3 text-[16px] text-foundation-dark-light__hover"
                  />
                  <SelectBox
                    shape="square"
                    indicator={
                      <Img
                        src="images/img_down_1.svg"
                        alt="Down 1"
                        className="h-[18px] w-[18px]"
                      />
                    }
                    name="Location Dropdown"
                    placeholder={`Event location`}
                    options={dropDownOptions}
                    className="w-full bg-white gap-4 border-[0.5px] border-solid border-foundation-dark-lighter"
                  />
                </div>
                <TextArea
                  shape="square"
                  name="Message TextArea"
                  placeholder={`Message`}
                  className="bg-white !border-foundation-dark-lighter placeholder-custom-placeholder text-foundation-dark-light__hover"
                />
              </div>
              <div className="flex gap-[30px] sm:flex-col">
                <CheckBox
                  name="Catalogue Checkbox"
                  label="Send me a PDF catalogue"
                  id="CatalogueCheckbox"
                  className="gap-2.5 text-[14px] font-semibold text-black-900_03"
                />
                <CheckBox
                  name="Newsletter Checkbox"
                  label="Subscribe to our newsletter"
                  id="NewsletterCheckbox"
                  className="gap-2.5 text-[14px] font-semibold text-black-900_03"
                />
              </div>
              <Button shape="round" className="self-stretch font-semibold">
                Submit
              </Button>
            </div>
          </div>
          <ScrollAnimation animateIn="fadeInLeft" animateOut="fadeOutLeft">
            <div className="w-full">
              <Img
                src={data?.background_image}
                alt="Pictogram Image"
                className="h-[568px] w-[100%] self-end object-contain md:w-full md:self-auto animate-fadeInRight sm:w-auto sm:absolute sm:right-0"
              />
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </>
  );
}
