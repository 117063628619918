import React from "react";

const sizes = {
  textxs: "text-[12px] font-normal",
  texts: "text-[14px] font-normal",
  textmd: "text-[16px] font-normal",
  textlg: "text-[25px] font-normal not-italic md:text-[23px] sm:text-[21px]",
  textxl: "text-[30px] font-normal not-italic md:text-[28px] sm:text-[26px]",
  text2xl: "text-[32px] font-normal not-italic md:text-[30px] sm:text-[28px]",
  text3xl: "text-[45px] font-normal not-italic md:text-[41px] sm:text-[35px]",
  text4xl: "text-[60px] font-normal not-italic md:text-[52px] sm:text-[46px]",
  text5xl: "text-[75px] font-normal not-italic md:text-[48px]",
};

const Text = ({ children, className = "", as, size = "textmd", ...restProps }) => {
  const Component = as || "p";

  return (
    <Component className={`text-black-900_03 font-opensans ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };
