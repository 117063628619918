import React from "react";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div
      style={{
        height: "100vh",
        padding: "20px",
        backgroundColor: "#232323",
        color: "white",
      }}
    >
      <h1 style={{ fontSize: "48px", marginBottom: "20px" }}>Homepage</h1>
      <ul style={{ listStyle: "none", padding: "0" }}>
        <li style={{ marginBottom: "10px" }}>
          <Link
            to="/homepage"
            style={{ color: "#87CEFA", textDecoration: "none" }}
          >
            Homepage
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link
            to="/catalogue"
            style={{ color: "#87CEFA", textDecoration: "none" }}
          >
            Catalogue
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link
            to="/productpage"
            style={{ color: "#87CEFA", textDecoration: "none" }}
          >
            ProductPage
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link
            to="/inspirationpage"
            style={{ color: "#87CEFA", textDecoration: "none" }}
          >
            InspirationPage
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link
            to="/viewcart"
            style={{ color: "#87CEFA", textDecoration: "none" }}
          >
            ViewCart
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link
            to="/enquirewithproducts"
            style={{ color: "#87CEFA", textDecoration: "none" }}
          >
            Enquirewithproducts
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link
            to="/enquirewithoutproducts"
            style={{ color: "#87CEFA", textDecoration: "none" }}
          >
            Enquirewithoutproducts
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link
            to="/aboutus"
            style={{ color: "#87CEFA", textDecoration: "none" }}
          >
            AboutUs
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link
            to="/termsorprivacypolicy"
            style={{ color: "#87CEFA", textDecoration: "none" }}
          >
            TermsorPrivacyPolicy
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link
            to="/careers"
            style={{ color: "#87CEFA", textDecoration: "none" }}
          >
            Careers
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default Home;
