import { Img, Heading, Button, Text } from "./..";
import React from "react";

export default function ProductDetail({
  productTitle = "Bohemian Bliss Armchair",
  quantityText = "1",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center gap-[22px] flex-1`}
    >
      <div className="flex items-start gap-5 self-stretch rounded">
        <Img
          src="images/img_mask_150x120.png"
          alt="Product Image"
          className="h-[150px] w-[38%] self-center rounded object-contain"
        />
        <div className="flex-1">
          <div className="flex flex-col items-start gap-3">
            <div className="flex items-start gap-3.5 self-stretch">
              <Text
                size="textlg"
                as="p"
                className="w-[84%] self-center !font-marlidedisplay leading-[30px]"
              >
                {productTitle}
              </Text>
              <Button
                color="french_grey"
                size="xs"
                variant="outline"
                className=" w-[40px] h-[40px] rounded-[50px]"
              >
                <Img
                  src="images/img_delete_1.svg"
                  className="w-[30px] h-[30px]"
                />
              </Button>
            </div>
            <div className="flex w-[56%] items-center justify-center gap-5 rounded border border-solid border-french_grey p-2.5">
              <div className="h-px w-[14px] flex-1 rounded-[1px] bg-black-900_03" />
              <Heading
                size="headinglg"
                as="h6"
                className="tracking-[0.36px] !text-space_cadet"
              >
                {quantityText}
              </Heading>
              <Img
                src="images/img_plus.svg"
                alt="Add Image"
                className="h-[14px] w-[14px] rounded"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="h-px w-full self-stretch bg-french_grey" />
    </div>
  );
}
