import { Img } from "./..";
import React from "react";

export default function UserProfileImage({ image, ...props }) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center px-2.5 py-3 flex-1`}
    >
      <Img
        src={image}
        alt="Product Image"
        className="w-full h-full object-cover"
      />
    </div>
  );
}
