import { Helmet } from "react-helmet";
import {
  Button,
  Img,
  RatingBar,
  Heading,
  Text,
  Slider,
} from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import FurnitureShowcaseSection from "./FurnitureShowcaseSection";
import InspirationSection from "./InspirationSection";
import MeetTheTeamSection from "./MeetTheTeamSection";
import RequestQuoteSection from "./RequestQuoteSection";
import React, { useEffect, useState } from "react";
import { apiCall } from "utils";
import { API } from "constant";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";

export default function HomepagePage() {
  const [headerData, setHeaderData] = useState(null);
  const [homePageData, setHomePageData] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sliderState, setSliderState] = React.useState(0);
  const sliderRef = React.useRef(null);

  useEffect(() => {
    fetchThemeOption();
    fetchHomePageData();
    getGallery();
    getTestimonials();
  }, []);

  useEffect(() => {
    if (headerData && homePageData && galleryData && testimonialsData) {
      setLoading(false);
    }
  }, [headerData, homePageData, galleryData, testimonialsData]);

  const fetchThemeOption = async () => {
    try {
      const response = await apiCall("GET", API.themeOptions, null, {}, true); // Pass true if auth is required
      setHeaderData(response);
    } catch (err) {
      setError(err.message || "Error fetching data");
    }
  };

  const fetchHomePageData = async () => {
    try {
      const response = await apiCall("GET", API.homePage, null, {}, true); // Pass true if auth is required
      setHomePageData(response?.[0]?.acf);
    } catch (err) {
      setError(err.message || "Error fetching data");
    }
  };

  const getGallery = async () => {
    try {
      const response = await apiCall("GET", API.getGallery, null, {}, true); // Pass true if auth is required
      setGalleryData(response);
    } catch (err) {
      setError(err.message || "Error fetching data");
    }
  };

  const getTestimonials = async () => {
    try {
      const response = await apiCall("GET", API.testimonials, null, {}, true); // Pass true if auth is required
      setTestimonialsData(response);
    } catch (err) {
      setError(err.message || "Error fetching data");
    }
  };
  console.log("loading", loading);

  return loading ? (
    <div className="w-full flex justify-center items-center h-screen">
      Data is Loading
    </div>
  ) : (
    <>
      <Helmet>
        <title>Event Furniture Rental - Forevent KSA</title>
        <meta
          name="description"
          content="Discover Forevent's luxury furniture rentals for events in KSA. Elevate your occasion with our stylish seating, tables, and outdoor pieces. Get inspired and request a quote today."
        />
      </Helmet>
      <div className="w-full bg-white">
        <div>
          <div
            className="flex h-[900px] items-start justify-center bg-cover bg-no-repeat md:h-auto sm:py-5 custom-bg"
            style={{
              backgroundImage: `url(${homePageData?.hero_section_setting?.hero_background_image})`,
            }}
          >
            <div className="container-xs mb-[310px] flex justify-center md:px-5">
              <div className="flex w-full flex-col items-center gap-[232px] md:gap-[174px] sm:gap-[116px]">
                <Header
                  data={headerData}
                  className="gap-5 self-stretch py-8 "
                  isHomePage
                />
                <div className="flex w-[46%] flex-col items-center gap-8 md:w-full">
                  <ScrollAnimation
                    animateIn="fadeInLeft"
                    animateOut="fadeOutLeft"
                  >
                    <Text
                      size="text5xl"
                      as="p"
                      className="text-center !font-marlidedisplay leading-[84px] !text-gray-300 sm:leading-tight"
                    >
                      {homePageData?.hero_section_setting?.hero_section_text}
                    </Text>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeInLeft"
                    animateOut="fadeOutLeft"
                  >
                    <Link
                      to={
                        homePageData?.hero_section_setting?.hero_button_url ??
                        "#"
                      }
                      target="_blank"
                    >
                      <Button
                        color="gray_300"
                        variant="outline"
                        shape="round"
                        rightIcon={
                          <div className="flex h-[10px] w-[4px] items-center justify-center">
                            <Img
                              src="images/img_arrowright_gray_300_1.svg"
                              alt="Arrow Right"
                              className="mb-1 mt-1.5 h-[10px] w-[4px]"
                            />
                          </div>
                        }
                        className="min-w-[182px] gap-2.5 !border font-semibold text-gray-300"
                      >
                        {homePageData?.hero_section_setting?.hero_button_text}
                      </Button>
                    </Link>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>

          {/* furniture showcase section */}
          <FurnitureShowcaseSection
            data={homePageData?.category_section_setting}
          />
          <div className="relative mt-28 ">
            <div className=" m-auto flex h-max flex-1 flex-col items-center ">
              {/* inspiration section */}
              <InspirationSection
                data={homePageData?.get_inspired_section_setting}
                galleryData={galleryData}
              />
            </div>
            <div className="  m-auto flex flex-1 items-center md:relative md:flex-col sm:mt-10">
              <div className="w-[22%] ">
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  animateOut="fadeOutLeft"
                >
                  <Img
                    src={
                      homePageData?.team_section_setting
                        ?.content_background_image
                    }
                    alt="Pictogram Image"
                    className="h-auto w-[100%] object-contain md:w-full sm:absolute sm:top-[350px] sm:w-10/12 sm:left-0"
                  />
                </ScrollAnimation>
              </div>

              {/* meet the team section */}
              <MeetTheTeamSection data={homePageData?.team_section_setting} />
            </div>
            <div className="container-xs md:px-5">
              <div className="h-px bg-french_grey" />
            </div>
          </div>
          <div className="mt-[68px] flex items-end justify-center md:flex-col">
            <Button
              onClick={() => {
                sliderRef?.current?.slidePrev();
              }}
              color="gray_300_01"
              size="4xl"
              shape="round"
              className="mb-[78px] w-[68px] rotate-[-180deg] !rounded-sm border border-solid border-gray-200 sm:py-5 sm:hidden"
            >
              <Img src="images/img_arrow_right.svg" />
            </Button>
            <div className="flex w-[91%] flex-col items-center self-center px-[50px] md:w-full md:px-5">
              <Text
                size="text4xl"
                as="p"
                className="!font-marlidedisplay tracking-[0.60px] sm:text-40 sm:mb-5"
              >
                {homePageData?.testimonial_section_setting?.title}
              </Text>
              <Text
                as="p"
                className="mt-1 w-[50%] text-center leading-20 !text-gray-800 md:w-full sm:text-16 sm:leading-20"
              >
                {homePageData?.testimonial_section_setting?.tital_description}
              </Text>
              <div className="mx-auto mt-[72px] flex w-full self-stretch">
                <Slider
                  // autoPlay
                  autoPlayInterval={2000}
                  responsive={{
                    0: { items: 1 },
                    551: { items: 1 },
                    1051: { items: 2 },
                  }}
                  disableDotsControls
                  activeIndex={sliderState}
                  onSlideChanged={(e) => {
                    setSliderState(e?.item);
                  }}
                  ref={sliderRef}
                  items={testimonialsData?.map((item, index) => (
                    <React.Fragment key={Math.random()}>
                      <div className="flex items-center md:flex-col">
                        <div className="w-full">
                          <div className="flex flex-col gap-[52px] sm:gap-[26px]">
                            <div className="relative h-[176px]">
                              <Img
                                src="images/img_vector.svg"
                                alt="Testimonial Image"
                                className="absolute bottom-px left-0 m-auto h-[166px] w-[36%] object-contain sm:bottom-10 sm:w-1/2"
                              />
                              <Text
                                size="textxl"
                                as="p"
                                className="absolute left-0 top-0 m-auto w-[90%] !font-marlidedisplay leading-10 tracking-[0.30px] sm:text-25"
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item?.content,
                                  }}
                                />
                              </Text>
                            </div>
                            <div className="flex justify-between gap-5 items-center">
                              <Heading
                                as="h1"
                                className="!font-semibold !text-gray-900_01 sm:text-16"
                              >
                                {item?.acf?.client_name}
                              </Heading>
                              <RatingBar
                                value={5}
                                isEditable={true}
                                size={24}
                                className="mr-[52px] flex gap-2.5 sm:mr-0"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                />
              </div>
            </div>
            <Button
              color="gray_300_01"
              size="4xl"
              shape="round"
              onClick={() => {
                sliderRef?.current?.slideNext();
              }}
              className="mb-[78px] w-[68px]  !rounded-sm border border-solid border-gray-200 sm:py-5 sm:hidden"
            >
              <Img src="images/img_arrow_right.svg" />
            </Button>
            <div className="relative sm:flex sm:justify-evenly sm:w-full hidden sm:mt-[30px]">
              <Button
                onClick={() => {
                  sliderRef?.current?.slidePrev();
                }}
                color="gray_300_01"
                size="4xl"
                shape="round"
                className="mb-[78px] w-[68px] rotate-[-180deg] !rounded-sm border border-solid border-gray-200 sm:py-5 sm:bg-transparent sm:border-none sm:m-0"
              >
                <Img src="images/img_arrow_right.svg" />
              </Button>
              <Button
                color="gray_300_01"
                size="4xl"
                shape="round"
                onClick={() => {
                  sliderRef?.current?.slideNext();
                }}
                className="mb-[78px] w-[68px]  !rounded-sm border border-solid border-gray-200 sm:py-5 sm:bg-transparent sm:border-none sm:m-0"
              >
                <Img src="images/img_arrow_right.svg" />
              </Button>
            </div>
          </div>

          {/* request quote section */}
          <RequestQuoteSection data={homePageData?.quote_section_setting} />
          <Footer data={headerData?.header?.footer_settings} />
        </div>
      </div>
    </>
  );
}
