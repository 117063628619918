import { Img, Text } from "./..";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Header({
  isHomePage = false,
  className,
  data,
  ...props
}) {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const styleClass = isHomePage ? "!text-gray-50" : "!text-black-900 ";

  return (
    <header
      {...props}
      className={`${className} flex justify-between items-center sm:flex-col`}
    >
      <Img
        src={
          isHomePage
            ? data?.header?.header_setting?.light_logo
            : data?.header?.header_setting?.dark_logo
        }
        alt="Header Logo"
        onClick={() => navigate("/")}
        className="h-[40px] w-[154px] object-contain sm:hidden hover:cursor-pointer"
      />

      {/* Desktop Menu */}
      <div className=" sm:hidden md:flex flex items-center justify-between w-[66%] gap-5 mb-1.5">
        <ul className="flex flex-wrap gap-[46px]">
          {data?.menu?.map((menuItem, index) => (
            <li
              key={index}
              className="hover:transition  hover:border-b-2 hover:border-deep_orange-a400 border-b-2 border-transparent"
            >
              <Link to={menuItem?.url}>
                <Text as="p" className={styleClass}>
                  {menuItem?.title}
                </Text>
              </Link>
            </li>
          ))}
        </ul>
        <a href="#">
          <Img
            src={
              isHomePage
                ? data?.header?.header_setting?.cart_icon
                : data?.header?.header_setting?.cart_icon_dark
            }
            alt="Cart Icon"
            className="h-[24px] w-[24px] shake-on-hover transition"
          />
        </a>
      </div>

      {/* Hamburger Icon for Mobile */}
      <div className="sm:flex justify-between w-full hidden">
        <Img
          src={
            isHomePage
              ? data?.header?.header_setting?.light_logo
              : data?.header?.header_setting?.dark_logo
          }
          alt="Header Logo"
          className="h-[40px] w-[154px] object-contain sm:block hidden"
        />
        <div className="flex items-center">
          <Img
            src={
              isHomePage
                ? data?.header?.header_setting?.cart_icon
                : data?.header?.header_setting?.cart_icon_dark
            }
            alt="Hamburger Menu Icon"
            className="h-[24px] w-[24px] sm:block hidden"
          />
          <button
            className="sm:block hidden pl-5"
            onClick={toggleMenu}
            aria-label="Toggle Menu"
          >
            <Img
              src={
                isHomePage
                  ? data?.header?.header_setting?.menu_icon
                  : data?.header?.header_setting?.menu_icon_dark
              }
              alt="Menu Icon"
              className="h-[24px] w-[24px]"
            />
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`sm:flex flex-col items-center justify-center bg-white absolute top-0 left-0 w-full p-6 transition-transform transform ${
          isMenuOpen ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <button
          className="absolute top-4 right-4"
          onClick={closeMenu}
          aria-label="Close Menu"
        >
          <Img
            src="images/close.png"
            alt="Close Icon"
            className="h-[24px] w-[24px]"
          />
        </button>
        <ul className="flex flex-col gap-4 w-full">
          {data?.menu?.map((menuItem, index) => (
            <li key={index}>
              <Link to={menuItem?.url}>
                <Text as="p" className="text-black">
                  {menuItem?.title}
                </Text>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
}
