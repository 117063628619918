import { Text } from "../../components";
import UserProfile from "../../components/UserProfile";
import React, { Suspense } from "react";

export default function ProductPairingSection({ data }) {
  return (
    <>
      {/* product pairing section */}
      <div className="flex flex-col items-center mb-12">
        <div className="container-xs flex flex-col items-center gap-8 md:px-5">
          <Text
            size="text3xl"
            as="p"
            className="!font-marlidedisplay tracking-[0.45px] mt-20 mb-14"
          >
            Pairs well with
          </Text>
          <div className="flex gap-6 self-stretch md:flex-col">
            <Suspense fallback={<div>Loading feed...</div>}>
              {data.map((item, index) => (
                <UserProfile
                  data={item}
                  key={"itemsList" + index}
                  className="w-[24%] md:w-full"
                />
              ))}
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}
