import { Helmet } from "react-helmet";
import { Button, Img, Text, Heading } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import React, { useEffect, useState } from "react";
import { apiCall } from "utils";
import { API } from "constant";
import InspirationSection from "./InspirationSection";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

export default function InspirationPagePage() {
  const [headerData, setHeaderData] = useState(null);
  const [galleryData, setGalleryData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchThemeOption();
    getGallery();
    getCategory();
  }, []);

  const fetchThemeOption = async () => {
    try {
      const response = await apiCall("GET", API.themeOptions, null, {}, true); // Pass true if auth is required
      setHeaderData(response);
    } catch (err) {
      setError(err.message || "Error fetching data");
    }
  };

  const getGallery = async () => {
    try {
      const response = await apiCall("GET", API.getGallery, null, {}, true); // Pass true if auth is required
      setGalleryData(response);
    } catch (err) {
      setError(err.message || "Error fetching data");
    }
  };

  const getCategory = async () => {
    try {
      const response = await apiCall(
        "GET",
        API.getInspirationCategory,
        null,
        {},
        true
      ); // Pass true if auth is required
      const formatedCat = buildCustomCategoryTree(
        response?.filter((category) => category.slug !== "uncategorized")
      );
      setCategoryList(formatedCat);
    } catch (err) {
      setError(err.message || "Error fetching data");
    }
  };

  function buildCustomCategoryTree(categories) {
    const categoryMap = {};
    const tree = [];

    // Create a map of all categories by ID
    categories.forEach((category) => {
      categoryMap[category.id] = { name: category.name, child_cate: [] };
    });

    // Build the tree structure
    categories.forEach((category) => {
      if (category.parent === 0) {
        // Top-level category (parent)
        tree.push(categoryMap[category.id]);
      } else {
        // Child category
        categoryMap[category.parent].child_cate.push(categoryMap[category.id]);
      }
    });

    return tree;
  }

  console.log("categoryList", categoryList);

  return (
    <>
      <Helmet>
        <title>
          Inspiration Catalogue - Discover Corporate & Luxury Event Styles
        </title>
        <meta
          name="description"
          content="Explore our Inspiration Catalogue for your next corporate, sporting, or luxury event. Find the perfect style and join our email list for updates."
        />
      </Helmet>
      <div className="flex w-full flex-col items-center bg-white">
        <div className="container-xs  flex justify-center md:px-5 border-gray-300  border-b">
          <div className="flex w-full flex-col items-center  sm:gap-[116px]">
            <Header data={headerData} className="gap-5 self-stretch py-8 " />
          </div>
        </div>
        <div className="container-xs mt-[30px] md:px-5">
          <div className="flex items-start md:flex-col">
            <div className="flex flex-1 flex-col items-start gap-[34px] self-center md:self-stretch">
              <Text
                size="text4xl"
                as="p"
                className="!font-marlidedisplay w-full flex justify-center tracking-[0.60px] md:ml-0"
              >
                Inspiration
              </Text>
              <div className="ml-[38px] flex justify-start flex-row items-start gap-[50px]  md:ml-0 md:gap-[50px] sm:gap-[35px]">
                <Accordion
                  preExpanded={[1]}
                  className=" w-[25%] flex-1 flex-col gap-14 h-full sm:hidden"
                >
                  {" "}
                  {categoryList?.map((category, index) => (
                    <AccordionItem uuid={index} key={`Categories List${index}`}>
                      <div className="flex flex-1 flex-col gap-4">
                        <AccordionItemHeading className="w-full">
                          <AccordionItemButton>
                            <AccordionItemState>
                              {(props) => (
                                <>
                                  <div className="flex items-start justify-between gap-5 py-3 ">
                                    <div className="flex items-center gap-1.5 self-center">
                                      <Heading
                                        as="h1"
                                        className="!text-gray-900"
                                      >
                                        {category?.name}
                                      </Heading>
                                    </div>
                                    {props?.expanded ? (
                                      <Img
                                        src="images/img_vector_1x14.png"
                                        alt="Seating Expand Icon"
                                        className="mt-2.5 h-px object-cover"
                                      />
                                    ) : (
                                      <Img
                                        src="images/img_plus.svg"
                                        alt="New Expand Icon"
                                        className="h-[14px] w-[14px]"
                                      />
                                    )}
                                  </div>
                                </>
                              )}
                            </AccordionItemState>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="mb-7 flex flex-col items-start gap-3.5">
                            {category?.child_cate?.map((subCat, sIndex) => (
                              <Text as="p" className="!text-gray-800">
                                {subCat?.name}
                              </Text>
                            ))}
                          </div>
                        </AccordionItemPanel>
                      </div>
                      <div className="h-px w-[100%] rotate-[0deg] bg-french_grey" />
                    </AccordionItem>
                  ))}
                </Accordion>

                <div className="self-stretch  w-[75%] sm:w-full">
                  <div className="flex gap-3.5 md:flex-col">
                    <div className="flex flex-1 flex-col gap-3.5 md:self-stretch">
                      <InspirationSection galleryData={galleryData} />
                    </div>
                  </div>
                  <div className="flex justify-center w-full my-10">
                    <Button
                      color="gray_900_01"
                      variant="outline"
                      shape="round"
                      className="min-w-[138px] font-semibold"
                    >
                      Load More
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer data={headerData?.header?.footer_settings} className="w-full" />
      </div>
    </>
  );
}
