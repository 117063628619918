import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import EnquiryFormSection from "./EnquiryFormSection";
import React from "react";

export default function EnquirewithoutproductsPage() {
  return (
    <>
      <Helmet>
        <title>General Enquiry - Forevent</title>
        <meta
          name="description"
          content="Reach out to us with your event details and questions. Provide your contact information and subscribe to our newsletter for the latest updates."
        />
      </Helmet>
      <div className="flex w-full flex-col gap-8 bg-white">
        <Header className="mt-8 gap-5" />

        {/* enquiry form section */}
        <EnquiryFormSection />
        <Footer />
      </div>
    </>
  );
}
